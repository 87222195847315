import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

export const PricingCard = ({item, link}) => {
    const [id, setId] = useState('')

    useEffect(() => {
        if (item.packagename === "Banner Package 1") {
            setId('first-card')
        } else if (item.packagename === "Banner Package 2") {
            setId('second-card')
        } else if (item.packagename === "Banner Package 3") {
            setId('third-card')
        } else if (item.packagename === "Banner Package 4") {
            setId('fourth-card')
        }
    }, [item])

    return (
        <div className={'card'} id={id}>
            <div className={'card-title'}>
                {item.validity} Days
            </div>
            <div className={'card-price'}>
                <p>RS. {item.price * (100 - item.discount) / 100}/-</p>
                <span>RS. {item.price} /</span>
            </div>
            <div className={'card-discount'}>
                <p>Discount: <span>{item.discount}</span>%</p>
            </div>
            <div className={'card-description'}>
                <div className={'card-content'}>
                    <p className={'card-subtitle'}>Content</p>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>Over {item.videolesson} video lessons</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>Over {item.practiceques} practice questions</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>50+ grammar videos</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>Up to {item.scoredflt} scored full-length mock tests</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>{item.livesess} hours of live sessions</p>
                    </div>
                </div>
                <div className={'card-content'}>
                    <p className={'card-subtitle'}>Features</p>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>Study schedule</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>{item.validity} days of access</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>Post Test Consultation & SWOT Analysis</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>24/7 Online Tutor Support</p>
                    </div>
                    <div>
                        <DoneOutlinedIcon className={'card-icon'}/>
                        <p>Pause your plan</p>
                    </div>
                    <div>
                        {item.scorepred === 'true'?
                        <DoneOutlinedIcon className={'card-icon'}/>:
                        <CloseOutlinedIcon className={'card-icon'}/>}
                        <p className={item.scorepred === "true"?'':'over-lined-text'}>Score Predictor</p>
                    </div>
                </div>
            </div>
            <Link to={{pathname:link, 
                        state:{
                            package_val:item, 
                            discountedprice:item.price * (100 - item.discount) / 100}}}>
                        Sign up now</Link>
        </div>
    )
}