import React from 'react';
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {ProductBanner} from "../../common/ProductBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {InfoBanner} from "../../common/InfoBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {Universities} from "../../common/Universities";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";

export const GRE = () => {
    const data = {
        text: 'You are just a step away from your Dream University!',
        img: 'https://ugc.futurelearn.com/uploads/images/c6/98/thumbnail_c698c221-f7d3-4931-b3d4-30e8c7e8c1ff.jpg',
        link: '/GRE-pricing',
        name: 'GRE',
        registerLink: 'https://gre.testacademia.com/',
    }

    const text = [
        {
            title: "Structured curriculum",
            text: "In depth engaging video lessons by leading GRE experts with simple instructions and effective tricks and shortcuts for a high score. Stay focused!",
        },
        {
            title: "High quality Content",
            text: "2000+ practice questions and full length tests close to official GRE exam",
        },
        {
            title: "Analytics",
            text: "Receive useful stats on every question attempt for better comprehension of concepts",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <ProductBanner video={true} type={'gre'} data={data}/>
            <WhyWeBanner text={text} name={data.name}/>
            <FourthBanner/>
            <Universities/>           
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}