import React,{useState} from 'react';
import {FourthBanner} from "../Home/Banners/FourthBanner";
import {ProductBanner} from "../common/ProductBanner";
import {ReviewSlider} from "../Home/ReviewSlider";
import {InfoBanner} from "../common/InfoBanner";
import {WhyWeBanner} from "../common/WhyWeBanner";
import {Universities} from "../common/Universities";
import {MobileApp} from "../Home/Banners/MobileApp";
import {ThirdBanner} from "../Home/Banners/ThirdBanner";
import {MobileNavigation} from "../common/MobileNavigation";
import {Navigation} from "../common/Navigation"
import {TestimonialBanner} from "../common/TestimonialBanner"


export const Testimonial = () => {

    const [visibility, setVisibility] = useState(false)

    const data = {
        text: 'Improve your GMAT score by at least 50 points.',
        img: 'https://ugc.futurelearn.com/uploads/images/82/97/thumbnail_829723ee-79a7-48a2-a99b-4e7ec6a53354.jpg',
        link: '/GMAT-pricing',
        name: 'GMAT',
        registerLink: 'https://gmat.testacademia.com/',
        type: 'gmat',
        title: 'Best world class GMAT prep! Bridge the gap between you and your admission today!'
    }

    const text = [
        {
            title: "Structured curriculum",
            text: "In depth engaging video lessons by leading GMAT experts with simple instructions and effective tricks and shortcuts for a high score. Stay focused!",
        },
        {
            title: "High quality Content",
            text: "2000+ practice questions and full length tests close to official GMAT exam",
        },
        {
            title: "Analytics",
            text: "Receive useful stats on every question attempt for better comprehension of concepts",
        }
    ]

    return (
        <div>
             <MobileNavigation setVisibility={setVisibility} visibility={visibility}/>
             <Navigation setVisibility={setVisibility}/>
            <TestimonialBanner/>
            <ReviewSlider/>
            <FourthBanner/>
            <Universities/> 
        </div>
    )
}