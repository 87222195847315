import React from 'react';
import {Link} from "react-router-dom";

export const TestimonialBanner = () => {

    return (
        <div className={`testimonial-banner`}>
            <section>
                <div>
                    <h2 className={'testimonial-title'}>Hear from our students<br>
                    </br>What they have to say</h2>
                </div>
            </section>
        </div>
    )
}