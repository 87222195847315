import React from "react";
import {Navigation} from "./Navigation";
import loader from '../../assets/loader.svg'

export const Preloader = () => {
    return (
        <div>
            <Navigation/>
            <div className={'preloader'}>
                <img alt="Preloader of testacademia" src={loader}/>
            </div>
        </div>
    )
}