import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TrainIcon from '@material-ui/icons/Train';
import React from "react";

export const WhyWeBannerGovt = (props) => {
    const name = props.name
    const text = props.text
    const small = props.text

    return (
        <div className={'third-banner govt-third'}>
            <p className={'third-banner-title'} style={{textAlign:"center"}}>Choose from the variety of<br></br> exams we provide</p>
            <div className={'third-banner-big'} >
                <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <AccountBalanceIcon className={'third-banner-icon'}/>
                        <p className={'third-banner-item-title'}>Bank Exams</p>
                    </div>
                    <p className={'third-banner-item-subtitle'}>
                    Securing a stable Bank job and getting placed in the Banking sector has been one of the sought after career streams for many aspirants. With our rigorous conceptual and practice sessions, our students are able to achieve their dreams in the first attempt. 
                        <ul>
                            <li>IBPS PO</li>
                            <li>IBPS Clerk</li>
                            <li>SBI PO</li>
                        </ul>
                    </p>
                </div>
                <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <InsertDriveFileIcon className={'third-banner-icon'}/>
                        <p className={'third-banner-item-title'}>SSC Exams</p>
                    </div>
                    <p className={'third-banner-item-subtitle'}>
                    TestAcademia provides SSC Classes to students for various exams like CGL, CHSL, Stenographer, CPO, GD Constable, Multi-tasking, etc. We at TestAcademia believe that preparing for an exam can make all the difference.
                         <ul>   
                            <li>SSC CGL</li>
                            <li>SSC CPO</li>
                            <li>SSC CHSL</li>
                        </ul></p>
                </div>
                <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <TrainIcon className={'third-banner-icon'}/>
                        <p className={'third-banner-item-title'}>Railway Exams</p>
                    </div>
                    <p className={'third-banner-item-subtitle'}>
                    We at Testacademia help you prepare for various Railway exams as RRB ALP, RRB Group D, opening various avenues as Commercial apprentice, Goods guard, Traffic assistant, Assistant Station Master etc. With our rigorous conceptual and practice sessions, our students are able to achieve their dreams in the first attempt. 
                        <ul>
                            <li>Railways RRB ALP</li>
                            <li>Railways RRB Group D</li>
                        </ul></p>
                </div>
            </div>
        </div>
    )
}