import React from "react";

export const UniversitiesGovt = () => {
    return (
        <div className={'universities-section govt-uni'}>
            <p className={'page-title'}><span className={'sub-heading'}>Our Faculty</span><br />We have faculty who is experienced<br></br> in various government exams</p>
            <div className={'university-first-row'}>
                <div className={'exam-card'}>
                <img alt="SBI PO exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/sbi.png'}/>
                <p>SBI PO</p>
                </div>
                <div className={'exam-card'}>
                <img alt="SBI SO exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/sbi.png'}/>
                <p>SBI SO</p>
                </div>
                <div className={'exam-card'}>
                <img alt="SBI CLERK exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/sbi.png'}/>
                <p>SBI Clerk</p>
                </div>
                <div className={'exam-card'}>
                <img alt="IBPS PO exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ibps.png'}/>
                <p>IBPS PO</p>
                </div>
            </div>
            <div className={'university-second-row'}>
            <div className={'exam-card'}>
                <img alt="SSC CGL exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ssc.png'}/>
                <p>SSC CGL</p>
                </div>
                <div className={'exam-card'}>
                <img alt="SSC CPO exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ssc.png'}/>
                <p>SSC CPO</p>
                </div>
                <div className={'exam-card'}>
                <img alt="SSC CHSL exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ssc.png'}/>
                <p>SSC CHSL</p>
                </div>
                <div className={'exam-card'}>
                <img alt="IBPS CLERK exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ibps.png'}/>
                <p>IBPS CLERK</p>
                </div>
            </div>
            <div className={'university-second-row'}>
            <div className={'exam-card'}>
                <img alt="RRB GROUP D exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/railway.png'}/>
                <p>RRB GROUP D</p>
                </div>
                <div className={'exam-card'}>
                <img alt="RRB NTPC exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/railway.png'}/>
                <p>RRB NTPC</p>
                </div>
                <div className={'exam-card'}>
                <img alt="IBPS RRB exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ibps.png'}/>
                <p>IBPS RRB</p>
                </div>
                <div className={'exam-card'}>
                <img alt="IBPS SO exam Preparation Online" style={{height: '110px'}} src={'https://www.mahendras.org/DesktopModules/Sitepages/Exams/media/ibps.png'}/>
                <p>IBPS SO</p>
                </div>
            </div>
        </div>
    )
}