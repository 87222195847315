import React from 'react';
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {ProductBanner} from "../../common/ProductBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {InfoBanner} from "../../common/InfoBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {Universities} from "../../common/Universities";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";

export const SAT = () => {
    const data = {
        text: 'Improve your SAT score by at least 200 points!',
        img: 'https://ugc.futurelearn.com/uploads/images/79/ad/thumbnail_79adcfe2-21e6-4e93-9178-9c7db9cc2612.jpg',
        link: '/SAT-pricing',
        name: 'SAT',
        registerLink: 'https://sat.testacademia.com/',
        title: 'Best world class SAT prep! Lead your child to his dream college with an Ideal SAT score!'
    }

    const text = [
        {
            title: "Updated SAT pattern",
            text: "Practice questions, video lessons and AI scored mock tests are exactly as per examination pattern",
        },
        {
            title: "Progress Tracking",
            text: "You will be assigned a dedicated coordinator evaluating your\nperformance and motivating you towards your goal!",
        },
        {
            title: "Course coordinators",
            text: "You will be assigned a DEDICATED MANAGER tracking your progress and evaluating your performance. WEEKLY ASSIGNMENTS and customized ONE – TO – ONE feedback would enable you to stay focused!",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <ProductBanner type={'sat'} data={data}/>
            <WhyWeBanner small={true} text={text} name={data.name}/>
            <FourthBanner/>            
            <Universities/>
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}