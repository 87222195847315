import React, {useEffect, useState} from "react";
// import {PricingPage} from "../../common/PricingPage";
// import {PricingQuestions} from "../../common/PricingQuestions";
import {PricingPageForOET} from "../../common/PricingPages/PricingPageForOET";
import {PricingQuestionsForOET} from "../../common/PricingQuestionsPages/PricingQuestionsForOET";
import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
import {Preloader} from "../../common/Preloader";

export const OETPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/OET-register'
    const name = 'OET'
    const title = 'Improve your score today! Guaranteed!'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/oet/package/bannerpackage')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>
    return (
        <div>
            <PricingPageForOET title={title} name={name} registerLink={'https://oet.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                                  link={link}
                                                                  key={item.price}/>)}
            </div>
            <PricingQuestionsForOET/>
        </div>
    )
}