import React from 'react';
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {GovtBanner} from "./GovtBanner";
import {WhyWeBannerGovt} from "./WhyWeBannerGovt";
import {InfoBannerGovt} from "./InfoBannerGovt";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {FourthBannerGovt} from "./FourthBannerGovt";
import {Migration} from "../../common/Migration";
import {UniversitiesGovt} from "./UniversitiesGovt";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";

export const Govt = () => {
    const data = {
        text: 'Crack any kind of government exam with us',
        img: 'https://ugc.futurelearn.com/uploads/images/82/97/thumbnail_829723ee-79a7-48a2-a99b-4e7ec6a53354.jpg',
        link: '/govt-pricing',
        name: 'GOVT',
        registerLink: 'https://govt.testacademia.com/',
        type: 'govt',
        title: 'Best world class GMAT prep! Bridge the gap between you and your admission today!'
    }

    const text = [
        {
            title: "Structured curriculum",
            text: "In depth engaging video lessons by leading GMAT experts with simple instructions and effective tricks and shortcuts for a high score. Stay focused!",
        },
        {
            title: "High quality Content",
            text: "2000+ practice questions and full length tests close to official GMAT exam",
        },
        {
            title: "Analytics",
            text: "Receive useful stats on every question attempt for better comprehension of concepts",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <GovtBanner type={data.type} data={data}/>
            <WhyWeBannerGovt text={text} name={data.name}/>
            <FourthBannerGovt/>
            <UniversitiesGovt/>
            <InfoBannerGovt link={""} name={data.name}/>
        </div>
    )
}