import React from 'react'

export const PricingQuestionsForSAT = () => {
    return (
        <div className={'pricing-questions'}>
            <p className={'pricing-questions-title'}>Frequently Asked Questions</p>
            <div className={'pricing-question-content'}>
                <div className={'pricing-row'}>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Are the essays optional? Should I prepare for essay writing?</p>
                        <p className={'pricing-question-text'}>Several schools do not accept SAT scores alone – they require that you write the essays as well. Save yourself the heartbreak of finding out (too late) that your college of choice requires you to write the essays. Be prepared for all of the SAT question types, including the essay.
                            </p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>How will personalized weekly sessions help?</p>
                        <p className={'pricing-question-text'}>Its important to learn how to strategize your exam preparation and focus on your strengths and improve upon your weaknesses to get a high score! Regular interactions with the tutor with Personalised Feedback Sessions is CRUCIAL. Practice alone will not suffice.
                        We’ll help you identify where and why you’re losing points, and guide you on how to turn it around!
                        </p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>.Do I need to have taken the test before?</p>
                        <p className={'pricing-question-text'}>No! Many of our students are first-time SAT test-takers, so if you're ready to start studying now, you should go ahead and sign up! Our lessons start from the beginning and teach you everything you need to know about the SAT, so taking the test previously is not required.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is safe. Do not worry!
If you have trouble paying, email testacademiaglobal@gmail.com or whatsapp , we will get back to you asap.
.</p>
                    </div>
                    {/* <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>How does the course work?</p>
                        <p className={'pricing-question-text'}>It’s 100% online. No DVDs. No books. It works on both Macs and PCs: any device with an internet connection!</p>
                    </div> */}
                </div>
                {/* <div className={'pricing-row'}>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Do I need to have taken the test before?</p>
                        <p className={'pricing-question-text'}>No! Many of our students are first-time SAT test-takers, so if you're ready to start studying now, you should go ahead and sign up! Our lessons start from the beginning and teach you everything you need to know about the PTE, so taking the test previously is not required.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is secure. Do not worry!
                            If you are facing any trouble, please contact at support@testacademia.com or whatsapp at 9591722199</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is safe. Dont worry!</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Can I purchase multiple accounts?</p>
                        <p className={'pricing-question-text'}>You definitely can! Email us at support@testacademia.com and we'll help set you up.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Do you have iPad/iPhone/Android access?</p>
                        <p className={'pricing-question-text'}>Yes, we do! You can access all of the lessons anywhere with our apps, and you can use your mobile browser to get full access to the site.</p>
                    </div>
                </div> */}
          
          
          
            </div>
        </div>
    )
}