import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import person1 from '../../img/testimonial/image1.png'
import person2 from '../../img/testimonial/image2.png'
import person3 from '../../img/testimonial/image3.png'
import person4 from '../../img/testimonial/image4.png'
import person5 from '../../img/testimonial/image5.png'
import person6 from '../../img/testimonial/image6.jpg'
import person7 from '../../img/testimonial/image7.png'
import person8 from '../../img/testimonial/image8.jpg'
import person9 from '../../img/testimonial/image9.png'
import person10 from '../../img/testimonial/image10.jpg'
import person11 from '../../img/testimonial/image11.png'


export const ReviewSlider = () => {
    const settings = {
        infinite: true,
        dots: true,
        arrows: true,
        fade: true,
        speed: 150,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        swipeToSlide: true,
    }

    return (
        <Slider {...settings} className={'review-slider'}>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person1}/>
                <p className={'review-slide-name'}>Aditi Goel, IELTS (overall band - 8), PTE (Overall score – 88)</p>
                <p className={'review-slide-text'}>
                    "The videos and materials are exhaustive that would help you crack your results in less time. I took both PTE and IELTS from Testacademia. The test series especially the full length mock tests are accompanied by detailed analysis and my essay evaluations were very close to that of actual IELTS and PTE. Now I am finally well settled in Canada and I owe this to my tutors who were available for support all the time."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person2}/>
                <p className={'review-slide-name'}>Yograj Singh, Overall Band 7.5</p>
                <p className={'review-slide-text'}>"The online training provided by Testacademia was very helpful. The tutors were very supportive and were available all the time for support. I could have not achieved a good score without their guidance."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person9}/>
                <p className={'review-slide-name'}>Nisha, IELTS (Overall band -8)</p>
                <p className={'review-slide-text'}>"When I did not clear the exam twice, I had given up hope especially in writing module but the detailed evaluations and effective strategies helped me to scale up my scores in all the modules. It has been nine months now in Canada, I am well settled. This could have not been possible without a good score in IELTS and my tutors’ right guidance."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person3}/>
                <p className={'review-slide-name'}>Karishma, TOEFL (112)</p>
                <p className={'review-slide-text'}>"I took live classes with pre recorded sessions and it helped me to learn all the concepts and their application very well. I could revise my lessons nicely and write exam in 15 days. I am very happy with my experience at Testacademia which helped me accomplish my score in less time."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person5}/>
                <p className={'review-slide-name'}>Shreya, TOEFL Overall Score : 110</p>
                <p className={'review-slide-text'}>"I got some valuable tips from Maam that helped me improve my scores. Along with the practice classes, the online test platform was a very big help. Regular practice helped me in a lot of areas like proper time management, efficient note writing, spontaneous thinking for the speaking part, etc. Also, Maam continued to provide feedback on the writing and the speaking areas. This helped me a lot since speaking is the most challenging part and I needed a lot of improvement in this area."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person6}/>
                <p className={'review-slide-name'}>Prabhakar Reddy, IELTS Overall Band : 7.5</p>
                <p className={'review-slide-text'}>"I was not confident on my communication skills as I did not study in English medium but the confidence that I got with persistent support and mentoring of my tutors helped me not only get admit in my dream university but transformed me to become what I am now."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person7}/>
                <p className={'review-slide-name'}>Akhil Chadha, GRE  - 326</p>
                <p className={'review-slide-text'}>"The video sessions and test series of Test academia were very helpful and helped me get my desired score in verbal and quant in GRE. The detailed analysis of each question helped me crack the exam in a short span of 1.5 months."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person8}/>
                <p className={'review-slide-name'}>Avra Sengupta, Overall Band 8.5</p>
                <p className={'review-slide-text'}>"I had to give IELTS in a very short time and I had zero prep before it. Testacademia helped me with useful strategies and short cuts to crack the exam. The mock tests really boosted my confidence and the results were mostly exactly as per the real exam."</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person10}/>
                <p className={'review-slide-name'}>Abhiram, Overall Band 9.0</p>
                <p className={'review-slide-text'}>"My sincere thanks to Testacademia for helping me achieve a perfect score of 90 in PTE in first attempt. The content provided by them was very exhaustive and helpful. They teach specific strategies for each section and also provide individual feedback on the areas we need to concentrate. Practice tests and mock tests provided were extensive and helped me in time management along with accuracy. "</p>
            </div>
            <div className={'review-slide'}>
                <img alt="Testimonial of testacademia" src={person11}/>
                <p className={'review-slide-name'}>Meenakshi, Overall Band 7.5</p>
                <p className={'review-slide-text'}>"Success is either near or far dream for every aspiring candidate. Thank you 'Testacademia' for making my dream come true. The tips and tricks covered in depth with detailed evaluations were really beneficial."</p>
            </div>
        </Slider>
    )
}