import React, {useEffect, useState} from "react";
import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
// import {PricingPage} from "../../common/PricingPage";
import {PricingPageForGRE} from "../../common/PricingPages/PricingPageForGRE";
// import {PricingQuestions} from "../../common/PricingQuestions";
import {PricingQuestionsForGRE} from "../../common/PricingQuestionsPages/PricingQuestionsForGRE";
import {Preloader} from "../../common/Preloader";

export const GREPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/GRE-register'
    const name = 'GRE'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/gre/package/bannerpackage\n')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>

    return (
        <div>
            <PricingPageForGRE name={name} registerLink={'https://gre.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                                  link={link}
                                                                  key={item.price}/>)}
            </div>
            <PricingQuestionsForGRE/>
        </div>
    )
}