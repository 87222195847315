import React from 'react'
import {ExpertEnglishNavigation} from "../../Products/ExpertEnglish/ExpertEnglishNavigation";
import AccessibilityNewOutlinedIcon from "@material-ui/icons/AccessibilityNewOutlined";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const PricingPageForPTE = ({name, registerLink, title}) => {

    return (
        <div>
            <ExpertEnglishNavigation name={name} registerLink={registerLink}/>
            <div className={'pricing-page'}>
                <p className={'pricing-page-title'}>{title ? title : `Improve your ${name} score today by atleast 10 points! Guaranteed!`}</p>
                <p className={'pricing-page-subtitle'}>Our exhaustive content + Your commitment = Your Dream Score</p>
                <div className={'pricing-page-content-title'}>Here's what you get:</div>
                <div className={'pricing-page-content'}>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <AccessibilityNewOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>EXPERT-CREATED CONTENT</p>
                            <p>High-quality material with effective result driven strategies for each section of the PTE. Study anywhere, anytime with our PTE expert.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <OndemandVideoOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>FULL-LENGTH PRACTICE TEST</p>
                            <p>Time yourself taking a full-length practice test, just like you would on test day.
Detailed feedback on each question would help you understand your improvement areas and work on them.
                            </p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <ChatBubbleOutlineOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>OVER 100 VIDEO LESSONS</p>
                            <p>Learn everything from basic English grammar to advanced PTE strategies with our library of lesson videos.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <AccountCircleOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>STUDY SCHEDULE</p>
                            <p>Select the perfect study schedule based on your needs helping you to stay on track and motivated to achieve your career goals.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <CheckCircleOutlineOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>ACCURATE SCORE PREDICTOR</p>
                            <p>All the tests are AI scored just like the official PTE exam and predict the scores almost same as the ACTUAL SCORES.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <MailOutlineIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>24/7 EMAIL SUPPORT</p>
                            <p>Quick, helpful, email support from our PTE Experts, whenever you get stuck or just want some extra help.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}