import React, {useState} from "react";
import {Navigation} from "./Navigation";
import {MobileNavigation} from "./MobileNavigation";
import payalimg from "../../img/payal.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {TeamSlider} from './Slider'
import {TeamBanner} from './TeamBanner'

export const AboutUs = () => {
    const [visibility, setVisibility] = useState(false)
    
    return (
        <div className={'about-us-page'}>
            <MobileNavigation setVisibility={setVisibility} visibility={visibility}/>
            <Navigation setVisibility={setVisibility}/>
            <div>
                <div className={'about-us-content'}>
                    <p className={'page-title'}>About us</p>
                    <div className={'about-us-item'}>
                        <p className={'about-us-item-title'}>Our Mission and Vision</p>
                        <div>
                            <p>We envision to give quality education to all, giving wings to their dreams. We not only aim on results but also focus on overall development of our students. The curriculum of all courses is designed in such a way it grooms everyone on all the major aspects of communication, eventually preparing them for the competitive exams.</p>
                            <p>The training would equip the students with the requisite skillset and expertise to crack the exams and the implementation of the concepts learned in day to day life would enable the students to become more confident and presentable. We inspire you to realize your aspirations towards a successful career and a good life.</p>
                        </div>
                    </div>
                    <div className={'about-us-item about-us-item1'}>
                        <div>
                            <p>Focus on basic concepts progressing towards varied difficulty levels. Our dedicated team is passionate about teaching which is conspicuous in our methodologies. They specialize in preparing the students for all the standardised tests as IELTS, PTE, GRE, GMAT , TOEFL, OET and SAT upgrading the content on a regular basis as per the ongoing (current) examination pattern.</p>
                            <img alt="About TestAcademia" src={'https://www.ofx.com/-/media/Blog/Studying Abroad/US/Study_Abroad_US5.ashx'}/>
                        </div>
                    </div>
                    <div className={'about-us-item about-us-item2'}>
                        <div>
                            <img alt="Study Abroad" src={'https://www.hdfcsales.com/blog/wp-content/uploads/2019/08/hdfc-study-abroad-loans-for-bright-future.jpg'}/>
                            <p>UNPARALLELED SUPPORT given to the students. Around the clock email assistance gives you confidence in your preparation OUR PROMISE is our COMMITMENT and we adhere to all the features and claims promised to provide you a pleasant experience and fruitful results!</p>
                        </div>
                    </div>
                </div>
                <div className={'our-team'}>
                    <div>
                    <div className="container">
                    <p className={'our-team-title'}>Our team</p>
                    <p className={'our-team-subtitle'}>We are a team of EDUCATION GEEKS obsessed on creating EXPERT CONTENT to give you a WORLD CLASS TEST PREP! TAKE A PLUNGE AND REALIZE YOUR DREAM!</p>
                    </div>
                   </div>
                   
{/* Slider */}

<div  style={{marginTop: "20px",marginRight: "1rem", marginBottom: "80px",marginLeft: "1rem"}} >
    <TeamSlider></TeamSlider>
    </div>
    
{/* <TeamBanner /> */}
    
{/* slider end */}


                    {/* <div className={'team-members'}>
                        <div className={'team-member'}>
                            <img alt='' src={'https://www.testacademia.com/static/media/payal.c776b546.png'}/>
                            <p>Payal</p>
                            <span>Founder/Mentor</span>
                        </div>
                        <div className={'team-member'}>
                            <img alt='' src={'https://www.testacademia.com/static/media/piu-aspire.f2e77786.jpg'}/>
                            <p>Piu Chakraborthy</p>
                            <span>Certified Trainer</span>
                        </div>
                        <div className={'team-member'}>
                            <img alt='' src={'https://www.testacademia.com/static/media/aswin.4ad09ae5.jpg'}/>
                            <p>Aswin Bhagyanath</p>
                            <span>Certified Trainer</span>
                        </div>
                        <div className={'team-member'}>
                            <img alt='' src={'https://www.testacademia.com/static/media/vaidyanath-aspire.eb919c84.jpg'}/>
                            <p>Vaidyanath</p>
                            <span>Professor</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}