import React from 'react';
import {ExpertEnglishNavigation} from './ExpertEnglishNavigation';
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {ProductBanner} from "../../common/ProductBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {InfoBanner} from "../../common/InfoBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";
import  {NeedcourceBanner}from './NeedcourceBanner'

export const ExpertEnglish = () => {
    const data = {
        text: 'Get Fluent in English! Let the world listen to you!',
        img: 'https://ugc.futurelearn.com/uploads/images/d6/b0/thumbnail_d6b049a5-29eb-411f-8ccd-21b8524dc8d6.jpg',
        link: '/expert-english-pricing',
        name: 'Expert English',
        bg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ6f-nYkmQpJ1UJMYDKTgkyGLR-XkMkb8WVIl9CIHHxJA&usqp=CAU&ec=45690268',
        registerLink: '/expert-english-register',
        title: 'Engaging. Impactful. Get prepared for the competitive environment with Exhaustive English Lessons!'
    }

    const text = [
        {
            title: "STRUCTURED CURRICULUM",
            text: "The video lessons are prepared keeping in mind the varied caliber levels of students, beginning from basic and gradually progressing towards harder concepts and practice questions.",
        },
        {
            title: "CONSTANT FEEDBACK REPORTS",
            text: "The detailed feedbacks based on test performance, emphasize on the student’s improvement areas, enabling him to correct his mistakes and become better each day",
        },
        {
            title: "PERSONALIZED STUDY PLAN",
            text: "The daily tasks allocated through numbered videos helps you to comprehend and apply each concept appropriately and effectively.",
        },
        {
            title: "PROGRESS TRACKING",
            text: ": Tests are given at every milestone and we help you analyze your performance and customize your preparation at every stage.",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} link={data.link} registerLink={data.registerLink}/>
            <ProductBanner type={'english'} data={data}/>
            <NeedcourceBanner/>
            <WhyWeBanner text={text} name={data.name}/>
            
            <FourthBanner/>            
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}