import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import React from "react";
// import {Video} from "../Home/Banners/Video";

export const NeedcourceBanner = (props) => {
    const name = props.name
    const text = props.text
    const small = props.text

    return (
        <div className={'third-banner'}>
            <p className={'third-banner-title'}>Why do you need the course?</p>
            <div className={'third-banner-big'} style={small ? {justifyContent: 'center'} : undefined}>
                <div className={'third-banner-item-small'}>
                    {/* <div className={'third-banner-icon'}>
                       <p>Overall Development</p>
                    </div> */}
                    <p className={'third-banner-item-title'}>Overall Development</p>
                    <p className={'third-banner-item-subtitle'}>You desire to prove yourself and step up the corporate ladder with enhanced communication skills.</p>
                </div>
                <div className={'third-banner-item-small'}>
                    {/* <div className={'third-banner-icon'}>
                       
                        <p>Competitive exams</p>
                    </div> */}
                    <p className={'third-banner-item-title'}>Competitive exams</p>
                    <p className={'third-banner-item-subtitle'}>Helps you crack the English sections of various competitive exams like Bank PO, Civil services exams and Campus Placement Exams.</p>
         
                </div>
               
                <div className={'third-banner-item-small'}>
                    {/* <div className={'third-banner-icon'}>
                        
                        <p>Professional Courses</p>
                    </div> */}
                    <p className={'third-banner-item-title'}>Professional Courses</p>
                    <p className={'third-banner-item-subtitle'}>Helps to improve your Verbal basics for Study abroad exams (GRE, GMAT, SAT) and English Proficiency tests (IELTS and TOEFL).</p>
                   
                </div>
            </div>
        </div>
    )
}