import React from 'react'
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';

export const SecondBanner = () => {
    return (
        <div className={'second-banner'}>
            <p className={'second-banner-title'}>Why choose Testacademia?</p>
            <div className={'second-banner-content'}>
                <div className={'second-banner-item'}>
                    <div className={'second-banner-icon'}>
                        <div>
                            <AttachMoneyIcon className={'second-banner-icon-item'}/>
                        </div>
                    </div>
                    <div className={'second-banner-text'}>
                        <p className={'second-banner-text-title'}>Best Value</p>
                        <p>Offering highest value proposition of 10x. Get much more at an unmatched 1/5 th
                            the price of competitors.</p>
                    </div>
                </div>
                <div className={'second-banner-item'}>
                    <div className={'second-banner-icon'}>
                        <div>
                            <AccessibilityNewOutlinedIcon className={'second-banner-icon-item'}/>
                        </div>
                    </div>
                    <div className={'second-banner-text'}>
                        <p className={'second-banner-text-title'}>Customized</p>
                        <p>Experience a unique journey of preparation tailored to your abilities and goals
                            with personalized online assistance and detailed feedbacks.</p>
                    </div>
                </div>
                <div className={'second-banner-item'}>
                    <div className={'second-banner-icon'}>
                        <div>
                            <HelpOutlinedIcon className={'second-banner-icon-item'}/>
                        </div>
                    </div>
                    <div className={'second-banner-text'}>
                        <p className={'second-banner-text-title'}>Uninterrupted Learning</p>
                        <p>Anytime assistance from experts from top Business schools.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}