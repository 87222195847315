import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import StarIcon from '@material-ui/icons/Star';
 import first from '../../img/RoundImages/payal.png'
 import  third from '../../img/RoundImages/piu.png'
 import second from '../../img/RoundImages/vaidyanath.png'
// import second from 'https://www.testacademia.com/static/media/payal.c776b546.png'
// import third from 'https://www.testacademia.com/static/media/payal.c776b546.png'
 import fourth from '../../img/RoundImages/anupam.png'
 import fifth from  '../../img/RoundImages/bhargavi.png'
 import six from '../../img/RoundImages/shitij.png'
 import seven from '../../img/RoundImages/shiva.png'
// import eight from 'https://www.testacademia.com/static/media/payal.c776b546.png'
// import nine from  'https://www.testacademia.com/static/media/payal.c776b546.png'
// import homefirst from '../../assets/img/testimonials/1.JPG'
// import homesecond from '../../assets/img/testimonials/rewise2.JPG'
// import homethird from '../../assets/img/testimonials/old3.JPG'
// import homefourth from '../../assets/img/testimonials/old4.JPG'
// import homefifth from '../../assets/img/testimonials/old5.JPG'
// import homesix from '../../assets/img/testimonials/old6.JPG'
// import homeseven from '../../assets/img/testimonials/old7.JPG'


export const TeamSlider= () => {
    const settings = {
        // infinite: true,
        // dots: false,
        // arrows: false,
        // fade: true,
        // speed: 500,
        // draggable: true,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // autoplay: false,
        // autoplaySpeed: 8000,
        infinite: true,
        dots: true,
        arrows: false,
        fade: true,
        speed: 150,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        swipeToSlide: true,
    }

    const data = [
        {
            link: first,
            name: 'PAYAL',
                
            subtitle: 'Founder & Mentor',
            text: 'Founder of Aspire Global Education, an ardent and passionate educationist, with diverse experience in various sectors of education, her objective is to provide quality education and excellence towards accomplishment of desired scores. Associated with management colleges - FABS, Singhad Business School ,Edulite, etc and partnered with various online education consultants for their content development such as Jaro Education, Schoolguru, the rich experience has enabled her to design tailor made programs for our students and provide them customized solutions to their requirements.'
        },
        {
            link: second,
            name: 'Vaidyanath',
            
            subtitle: 'Professor',
            text: 'A highly qualified professor, associated with many esteemed universities having rich teaching experience of more than 30 years. He is a certified ETS trainer and has over 12 years of experience in IELTS, PTE,GRE and TOEFL trainings. He has won many accolades, one of most prestigious one is "Best Teacher Award" by Tamil Nadu Government.'
        },
        {
            link: third,
            name: 'Piu Chakraborthy',
            subtitle: 'Certified Trainer',
            text: 'A certified trainer and qualified professional with masters in English Language and Philosophy, her rich experience of 18 years in Education and Training is commendable. She has been associated with esteemed organizations in the past as Jindal Stainless, Aptech, ManhattanReview. She has earned certifications from PearsonVue, ETS, Metlife Solutions in IELTS, PTE and TOEFL courses.'
        },
        {
            link: fourth,
            name: 'Anupam Khurana',
            subtitle: 'B.Tech',
            text: 'His knowledge and mentoring has helped us to look at every aspect of each subject and provide simplistic solutions for better comprehension and application of concepts for all students. He contributes a lot towards regular upgradation and improvisation in content and teaching methodology towards academic excellence for our students.'
        },
        {
            link: fifth,
            name: 'Ms Bhargavi',
            subtitle: 'B.E',
            text: 'She is a certified tutor with almost a decade of teaching experience in different subjects GRE, IELTS , GMAT, SAT and PTE. Her innovative teaching methodology and effective presentation skills enable students to visualize the concepts and imbibe them in the most simplistic manner leading to high success rate for all her students.'
        },
        {
            link: six,
            name: 'Shitij Malik',
            subtitle: 'B.Tech, MBA',
            text: "A veteran with immense industry experience and an analytical bent of mind, his knowledge and understanding is impeccable. His exposure to practical experience has brought about realistic solutions leading to high success rate."
        },
        {
                link: seven,
                name: 'Shiva Reddy L.',
                subtitle: 'B.Tech',
                text: 'He is one of our dedicated tutors who can relate to students very well. His simple and interactive approach has helped students to score high grades very easily.'
        },
        // {
        //         link: eight,
        //         name: 'Pravin (Reading : 69, Writing : 71, Speaking : 73, Listening : 72)',
        //         text: "It was really great learning experience in aspire global for PTE exam. The staff's experience and field knowledge were remarkable and helped me to crack the PTE exam in my first attempt only. The training and practice material provided by them were very helpful. They helped me identify the mistakes which occurred repeatedly and solve that. I am really happy now to have cracked PTE with great score and wish other aspirants all the best."
        // },
        // {
        //     link: nine,
        //     name: 'Vinay Pallerla :  Overall Band-8, Listening-8.5, Reading-7, Writing-8, Speaking-9',
        //     text: 'All the material provided and the mock tests conducted were very helpful to improve my scores. Right from receptionist to faculty, everyone was friendly and supportive. Thanks for all the guidance in helping me achieve my desired score.'
        // }
    
    ]

    return (
        <div className={'slider-team'} style={{width: "70%",margin: "auto"}}>
            <Slider {...settings} >
                {data.map(review => <SlideItem data={review} key={review.link}/>)}
            </Slider>
        </div>
    )
}

const SlideItem = ({data}) => {
    return (
        // <div className={'review-slide card'}>
        //     <img alt='' src={data.link}/>
        //     <p className={'review-slide-name'}>{data.name}</p>
        //     <p className={'review-slide-text'}>{data.text}</p>
          
        // </div>
         <div  >
  <img src={data.link} alt="Avatar"  style={{width:"200px",width:"200px",objectFit:"cover", margin: "auto", padding:" 10px"}}></img>
  <div class="container">
    <h4 style={{textAlign:"center"}}><b>{data.name}</b></h4> 
    <p style={{textAlign:"center"}}>{data.subtitle}</p> 
    <p>{data.text}</p> 
  </div>
  </div>

    )
}