import React from 'react'

export const PricingQuestionsForGMAT = () => {
    return (
        <div className={'pricing-questions'}>
            <p className={'pricing-questions-title'}>Frequently Asked Questions</p>
            <div className={'pricing-question-content'}>
                <div className={'pricing-row'}>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Who should use Testacademia?</p>
                        <p className={'pricing-question-text'}>Anyone can use Testacademia, but it's best for individuals who fall into one of the following categories:
                        <ul><li> Self-studiers: those who prefer to study on their own time and at their own pace.</li>
                        <li> Video-lovers: those who learn better by watching video than through reading or live-learning.</li>
                        <li> Busy students and professionals: those who do not have much time to study and want to sneak in a practice session here and there.</li>
                            </ul>
                            </p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Can I have a discount on Testacademia GMAT?</p>
                        <p className={'pricing-question-text'}>Yes! Email us at support@testacademia.com, testacademiaglobal@gmail.com</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Can I get help with material from other test prep sites or books?</p>
                        <p className={'pricing-question-text'}>We do not answer questions about outside sources. To keep our prices affordable for students, we prioritize Testacademia and officially released materials.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>I am already a Testacademia student. Can I have a discount on Testacademia GMAT?</p>
                        <p className={'pricing-question-text'}>Yes! Email us at support@testacademia.com, testacademiaglobal@gmail.com.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>How does the course work?</p>
                        <p className={'pricing-question-text'}>It’s 100% online. No DVDs. No books. It works on both Macs and PCs: any device with an internet connection!</p>
                    </div>
                </div>
                <div className={'pricing-row'}>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Do I need to have taken the test before?</p>
                        <p className={'pricing-question-text'}>No! Many of our students are first-time GMAT test-takers, so if you're ready to start studying now, you should go ahead and sign up! Our lessons start from the beginning and teach you everything you need to know about the GMAT, so taking the test previously is not required.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is secure. Do not worry!
                            If you are facing any trouble, please contact at support@testacademia.com, testacademiaglobal@gmail.com or whatsapp at 9591722199</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is safe. Dont worry!</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Can I purchase multiple accounts?</p>
                        <p className={'pricing-question-text'}>You definitely can! Email us at support@testacademia.com, testacademiaglobal@gmail.com and we'll help set you up.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Do you have iPad/iPhone/Android access?</p>
                        <p className={'pricing-question-text'}>Yes, we do! You can access all of the lessons anywhere with our apps, and you can use your mobile browser to get full access to the site.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}