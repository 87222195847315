import axios from "axios";
import cookie from "react-cookies";


export const exportresponserazorpay = (response,user,pack)=>{
    if (cookie.load('userid')) {
        var values = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id,
            transact: {
                transactionid: response.razorpay_payment_id,
                transactionamount: pack.price,
                userid: cookie.load('userid'),
                name: user.name,
                email: user.email,
                mobile: user.mobile,
                institutename: user.institutename,
                packagebought: pack._id,
            }
        }
        if(user.course === "IELTS"){
        axios.post('https://api.testacademia.com/user/upgrade/payment', values)
            .then(res => {
                console.log(res.data.transaction.packagebought)

                axios.post('https://api.testacademia.com/user/upgrade/editstud/' + cookie.load('userid'), {userType: res.data.transaction.packagebought})
                    .then(result => {
                        alert("Account created.")
                    })
                    .catch(err => {
                        alert("closed")
                        console.log(err)
                    })
            })
            .catch(e => console.log(e))
        }
        else{
            var name = user.course.toLowerCase().replace(" ","","g");
            axios.post('https://api.testacademia.com/user/'+name+'/upgrade/payment', values)
            .then(res => {
                console.log(res.data.transaction.packagebought)

                axios.post('https://api.testacademia.com/user/'+name+'/upgrade/editstud/' + cookie.load('userid'), {userType: res.data.transaction.packagebought})
                    .then(result => {
                        alert("Account created.")
                    })
                    .catch(err => {
                        alert("closed")
                        console.log(err)
                    })
            })
            .catch(e => console.log(e))
        }
    }
}