import React from 'react'
import {ExpertEnglishNavigation} from "../Products/ExpertEnglish/ExpertEnglishNavigation";
import AccessibilityNewOutlinedIcon from "@material-ui/icons/AccessibilityNewOutlined";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const PricingPage = ({name, registerLink, title}) => {

    return (
        <div>
            <ExpertEnglishNavigation name={name} registerLink={registerLink}/>
            <div className={'pricing-page'}>
                <p className={'pricing-page-title'}>{title ? title : `Improve your ${name} score today by atleast 10 points! Guaranteed!`}</p>
                <p className={'pricing-page-subtitle'}>Our exhaustive content + Your commitment = Your Dream Score</p>
                <div className={'pricing-page-content-title'}>Here's what you get:</div>
                <div className={'pricing-page-content'}>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <AccessibilityNewOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>EXPERT-CREATED CONTENT</p>
                            <p>High-quality material with effective result driven strategies for each section of IELTS. Study anywhere, anytime with our IELTS expert.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <OndemandVideoOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>FULL-LENGTH PRACTICE TEST</p>
                            <p>Time yourself taking a full-length practice test, just like you would on test day.
                            Extensive practice sessions and Sectional tests would help you improve your individual modules. 
                            Full length tests would help you strategize the exam and accomplish your desired scores.
                            </p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <ChatBubbleOutlineOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>OVER 100 VIDEO LESSONS</p>
                            <p>Learn everything from basic English grammar to advanced IELTS strategies with our library of lesson videos.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <AccountCircleOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>STUDY SCHEDULE</p>
                            <p>Select the perfect study schedule based on your needs helping you to stay on track and motivated to achieve your career goals.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <CheckCircleOutlineOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>ACCURATE ASSESSMENTS</p>
                            <p>Our writing and speaking evaluations are very close to your REAL scores. Detailed feedback would help you improvise and achieve high bands.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <MailOutlineIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>24/7 EMAIL SUPPORT</p>
                            <p>Quick, helpful, email support from our IELTS Experts, whenever you get stuck or just want some extra help.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}