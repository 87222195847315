import React from 'react';

export const FourthBanner = () => {
    const testacademiaPlayMarketLink = 'https://play.google.com/store/apps/details?id=com.testacademia.ielts'
    const testacademiaAppStoreLink = 'https://apps.apple.com/in/app/complete-ielts-8-band-prep/id1524214654'
    const greenAppPlayMarketLink = 'https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app'
    const greenAppAppStoreLink = 'https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app'

    return (
        <div className={'fourth-banner'}>
            <div className={'fourth-banner-item'} id={'first-banner-item'}>
                <div className={'fourth-banner-item-promo'} style={{backgroundColor: 'rgba(43, 43, 43, 0.8)'}}>
                    <p>World's Highest Rated App</p>
                </div>
            </div>
            <div className={'fourth-banner-item'} id={'second-banner-item'}>
                <div className={'fourth-banner-item-review'}>
                    <div className={'fourth-banner-item-review-content'} style={{paddingTop: "50px"}}>
                        <p className={'review-title'}>IELTS 8+ band: Lessons, Practice &amp; Prep</p>
                        <p className={'review-body'}>&quot;A one-stop prep platform for IELTS &amp; English
                            Learners&quot;</p>
                        <img alt="Get 8+ band in ielts" width={'100px'} height={'220px'} src={'https://lh3.googleusercontent.com/h0L5-mJNkhEuJ04nJAk_0T2TOhmyc2u88f2-M3100wBo8Myuh4VMXBpNxxgvKPIUjhk=w720-h310-rw'}/>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <a target={"_blank"} href={testacademiaPlayMarketLink}>
                            <img alt="Ielts app on google appstore" width={'150px'} height={'70px'} src={'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'}/>
                        </a>
                        <a target={"_blank"} href={testacademiaAppStoreLink}>
                            <img alt="Ielts app on apple store" width={'150px'} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Download_on_iTunes.svg/440px-Download_on_iTunes.svg.png'}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className={'fourth-banner-item'} id={'third-banner-item'}>
                <div className={'fourth-banner-item-review'}>
                    <div className={'fourth-banner-item-review-content'} style={{paddingTop: "50px"}}>
                        <p className={'review-title'}>PTE Academic Vocab Builder App</p>
                        <p className={'review-body'}>&quot;An extensive vocabulary builder for 79+ aspirants&quot;</p>
                        <img alt="Pte app on apple store" width={'100px'} height={'220px'} src={'https://lh3.googleusercontent.com/3GIwlvJGGMSRG_W6F8IGZXT9IRKGOIS7ihe4-KhQJMCIIEAFq80CED4Ar8JOGP6RMA=w720-h310-rw'}/>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <a target={"_blank"} href={greenAppPlayMarketLink}>
                            <img alt="Pte app on google playstore" width={'170px'} height={'70px'} src={'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'}/>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}