import React from "react";
import {Navigation} from "./Navigation";

export const TermsAndCond = () => {

    return (
        <div>
            <Navigation/>
            <div className={'privacy-policy'}>
                <p className={'page-title'}>Terms of Use</p>

                We are striving to make the best test prep service for your money and help achieve your desired scores in the least possible time.
                The Testacademia service (collectively, “Testacademia”, “the Site”, or “the Service”) is operated by Aspire global Education . By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.


                <h2>1. Refund Policy/Payment Terms</h2>

                You agree to pay Testacademia the fee specified in the Payment. A valid credit card/modeof payment is required to access to the Service. The Fee is payable in advance for the Period. Fees are non-refundable and non-transferable. Accordingly, if you elect to cancel your access to the Service during the Period, you will not receive a refund of the Fee(s) previously paid to Testacademia. All detailed information on all the courses is clearly given so once the students enroll, refund is not liable to happen under any circumstances.

                <h2>2. Terms and Conditions: Registration Data; Account Security</h2>

                If you subscribe to any of Testacademia’s services, you will have an account and a password. Access to password protected portions of this Site is available for only so long as you have paid all required fees to Testacademia. You are responsible for maintaining the confidentiality of your account and password and for restricting access to Testacademia material to anyone other than yourself. 

                <h2>3. Use License</h2>

                1.	Permission is granted to a terminable non-exclusive, non-transferable license to use Testacademia 's web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                    1.	modify or copy the materials
                    2.	use the materials for any commercial purpose, or for any public display (commercial or non-commercial)
                    3.	attempt to decompile or reverse engineer any software contained on the  web site
                    4.	remove any copyright or other proprietary notations from the materials or
                    5.	transfer the materials to another person or "mirror" the materials on any other server.
                2.	This license shall automatically terminate if you violate any of these restrictions and may be terminated by Testacademia at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.

                <h2>4. Trademarks</h2>

                Testacademia and other Company graphics, logos, designs, page headers, button icons, scripts and service names are registered trademarks. Company's trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the Company.

                <h2>5. Warranties and Disclaimers</h2>

                1.	Testacademia and its subsidiaries, affiliates, officers, employees, agents, partners, and licensors make no warranty that (i) the service will meet your requirements; (ii) the service will be uninterrupted, timely, secure or error-free; (iii) the results that may be obtained from the use of the service will be accurate or reliable; (iv) the quality of any products, services, information or other material purchased or obtained by you through the service will meet your expectations; and (v) any errors in the software will be corrected.
                2.	The Service may be temporarily unavailable from time to time for maintenance or other reasons. Testacademia assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, communications.
                3.	Under no circumstances will Testacademia be responsible for any loss or damage, including any loss or damage to any User Content or personal injury or death, resulting from anyone's use of the Site or the Service, any User Content or Third-Party Applications, Software or Content posted on or through the Site or the Service or transmitted to Users, or any interactions between users of the Site, whether online or offline.


                <h2>6. User Content</h2>

                You understand that except for advertising programs offered by us on the Site, the Service and the Site are available for your personal, non-commercial use only. You represent, warrant and agree that no materials of any kind submitted through your account or otherwise posted, transmitted, or shared by you on or through the Service will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory or otherwise unlawful material.

                <h2>7. User Conduct</h2>

                You understand that the Site is available for your personal, non-commercial use only. You represent, warrant, and agree that no materials of any kind submitted through your account or otherwise posted, transmitted, or shared by you on or through the Service will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory or otherwise unlawful material.
                In addition, you agree not to use the Site to:
                •	harvest or collect email addresses or other contact information of other users from the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;
                •	use the Site in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Site;
                •	upload, post, transmit, share, store or otherwise make available any content that we deem to be harmful, threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent, invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise objectionable;
                •	use automated scripts to collect information from or otherwise interact with the Site;
                •	register for more than one User account, register for a User account on behalf of an individual other than yourself, or register for a User account on behalf of any group or entity;
                •	impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with any person or entity;
                •	upload, post, transmit, share or otherwise make available any unsolicited or unauthorized advertising, solicitations, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;
                •	upload, post, transmit, share, store or otherwise make publicly available on the Site any private information of any third party, including, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers;
                •	solicit passwords or personally identifying information for commercial or unlawful purposes;
                •	upload, post, transmit, share or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                •	intimidate or harass any other user.


                <h2>8. Revisions and Errata</h2>

                The materials appearing on Testacademia 's web site could include technical, typographical, or photographic errors. Testacademia does not warrant that any of the materials on its web site are accurate, complete, or current. Testacademia may make changes to the materials contained on its web site at any time without notice. Testacademia does not, however, make any commitment to update the materials.

                <h2>9. Links</h2>

                Testacademia has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Testacademia of the site. Use of any such linked web site is at the user's own risk.

                <h2>10. Site Terms of Use Modifications</h2>

                Testacademia may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.

                <h2>11. Proprietary Rights</h2>

                You acknowledge and agree that the Service and any necessary software used in connection with the Service contain proprietary and confidential information that are protected by applicable intellectual property and other laws. You further acknowledge and agree that content contained in information presented to you through the Service is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws.

                <h2>12. Privacy Policy</h2>

                You consent to the collection, processing and storage by Testacademia of your personal information in accordance with the terms of Testacademia’s Privacy Policy, which is available at https://testacademia.com/privacy-policy . You agree to comply with all applicable laws and regulations, and the terms of Testacademia’s Privacy Policy, with respect to any access, use and/or submission by you of any personal information in connection with this web site.

                <h2>13. Subscription Period</h2>

                You will be entitled to receive the Service only during the subscription period ("Subscription Period") specified by your payment confirmation.

                <h2>14. No Resale or Redistribution of the Service</h2>

                You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of the Service use of the Service, or access to the Service.

                <h2>15. Electronic Communications</h2>

                When you visit this website and when you communicate with us electronically, for example by sending us an email or ordering our products online, you consent to receive communications from us electronically. We will communicate with you by email or by posting notices on this website. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
            </div>
        </div>
    )
}