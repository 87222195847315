import React from "react";

export const Migration = () => {
    const link = 'https://www.youtube.com/embed/5Knwv1Q46XE'

    return (
        <div className={'migration-section'}>
            <p className={'page-title top-padding'} style={{marginTop:"60px"}}>Platform that opens global borders for you <br></br>Canada, Australia, Netherlands and other countries</p>
            <div className={'migration'}>
                <img alt="Crack exam to go to another country" src={'https://www.seekpng.com/png/full/26-262107_pin-world-map-online-pin-world-map-online.png'}/>
            </div>
        </div>
    )
}