import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import React from "react";

export const Address = () => {
    return (
        <div className={'address'}>
            <div>
                <div className={'address-icon'}>
                    <CallOutlinedIcon className={'address-icon-item'}/>
                </div>
                <p>Phone number: +91 9591722199</p>
            </div>
            <div>
                <div className={'address-icon'}>
                    <WhatsAppIcon className={'address-icon-item'}/>
                </div>
                <p>Whatsapp:+91 9591722199</p>
            </div>
            <div>
                <div className={'address-icon'}>
                    <MailOutlineIcon className={'address-icon-item'}/>
                </div>
                <p>Email:  support@testacademia.com, testacademiaglobal@gmail.com</p>
            </div>
            <div>
                <div className={'address-icon'}>
                    <HomeOutlinedIcon className={'address-icon-item'}/>
                </div>
                <p>Address: 13, 14 Marathahalli, Bengaluru, Karnataka: 560037</p>
            </div>
            <div>
                <div className={'address-icon'}>
                    <ScheduleOutlinedIcon className={'address-icon-item'}/>
                </div>
                <p>Schedule: Monday – Sunday 10:00 AM to 08:00 PM IST</p>
            </div>
        </div>
    )
}