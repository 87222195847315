import React, {useEffect, useState} from "react";
//import {PricingPage} from "../../common/PricingPage";
// import {PricingQuestions} from "../../common/PricingQuestions";
import {PricingPageForTOFEL} from "../../common/PricingPages/PricingPageForTOFEL";
import {PricingQuestionsForTOFEL} from "../../common/PricingQuestionsPages/PricingQuestionsForTOFEL";

import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
import {Preloader} from "../../common/Preloader";

export const TOEFLPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/TOEFL-register'
    const name = 'TOEFL'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/toefl/package/bannerpackage')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>
    return (
        <div>
            <PricingPageForTOFEL name={name} registerLink={'https://toefl.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                                  link={link}
                                                                  key={item.price}/>)}
            </div>
            <PricingQuestionsForTOFEL/>
        </div>
    )
}