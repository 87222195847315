import React, {Component,useState} from 'react';
import BlogRightAd from './blogrightad';
import {Navigation} from '../common/Navigation';
import {useParams} from 'react-router-dom'


const BlogView=() => {
    let id=useParams()

    console.log("Id is",id.id)

    const [data, setData] = useState([
        {
            id:1,
            imageuri:'https://hallmarkuniversity.edu/wp-content/uploads/2020/04/book-macbook-pro-working-studying-7354-scaled.jpg',
            author:'Payal Chadha',
            date:'24 May 2021',
            title:'IELTS Listening made easy.'
        },
        {
            id:2,
            imageuri:'https://preply.com/wp-content/uploads/2018/04/education-concept-student-studying-and-brainstorming-campus-concept-close-up-of-students-discussing-their-subject-on-books-or-textbooks-selective-focus_1418-627-2.jpg',
            author:'Payal Chadha',
            date:'12 Apr 2021',
            title:'Facing the biggest challenge in writing & speaking'
        },
        {
            id:3,
            imageuri:'https://insiderguides.com.au/wp-content/uploads/2020/02/Tips-for-acing-the-PTE_Insider_guides-1-scaled-1-1024x683.jpg',
            author:'Payal Chadha',
            date:'21 Jun 2021',
            title:'Get a Perfect Score in PTE speaking : Repeat Sentence : Strategies'
        },
        {
            id:4,
            imageuri:'https://www.usnews.com/dims4/USNEWS/439e191/2147483647/thumbnail/640x420/quality/85/?url=http%3A%2F%2Fmedia.beam.usnews.com%2F16%2F0f%2Fa0b53d604bc395a1b439ea09d742%2F180625-collegestudentstudying-stock.jpg',
            author:'Payal Chadha',
            date:'21 Jun 2021',
            title:'GMAT quant made easy! Tips and tricks to get a perfect score'
        },
        {
            id:5,
            imageuri:'https://images.indianexpress.com/2017/07/exam_student.jpg',
            author:'Payal Chadha',
            date:'21 Jun 2021',
            title:'GRE Overview : How to boost your GRE Verbal Score'
        }
    ])


    return(
        <>
        <Navigation/>
        <div className="container">
        <div className="row blogview">
          <div className="col-lg-8">
            <div className="blog_left_sidebar">
            <div className="single-post row">
                <div className="col-lg-12">
                    <div className="feature-img">
                    <img className="img-fluid" src={data[parseInt(id.id)-1].imageuri} alt={data[parseInt(id.id)-1].title} />
                    </div>
                </div>
                <div className="col-lg-3  col-md-3">
                    <div className="blog_info text-right">
                    <ul className="blog_meta list">
                        <li><a href="#">{data[parseInt(id.id)-1].author}<i className="lnr lnr-user" /></a></li>
                        <li><a href="#">{data[parseInt(id.id)-1].date}<i className="lnr lnr-calendar-full" /></a></li>
                    </ul>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9 blog_details">
                    <h2>{data[parseInt(id.id)-1].title}</h2>
                    <p className="excert">
                    {
                        parseInt(id.id)===1?(
                            <p>
                                Getting a band 8 in listening becomes challenging for many students. They get stuck with band 7.5 and just a couple of questions away from CLB9 and a favorable score for visa processing.
            Here are some sure shot tricks that never fail.
            <br></br><br></br><b>1. Be extra careful to avoid spelling and grammatical errors.</b><br></br>
            Many homophones are extremely confusing. Even if you know the word but make a spelling error like mail/male, sail/sale, you lose the points. Students tend to panic and quickly fill up the blank without ensuring the sentence is grammatically correct!
             <br></br><br></br><b>2. Make your notes while listening</b><br></br>
            You may find it hard to concentrate on the lecture listening to any kind of lecture or talk, and making notes at the same time so it is important that you practise jotting down keywords in your practice sessions to enhance your comprehension.
            <br></br><br></br><b>3. Get familiar with the questions :Prediction works wonders</b><br></br>
            During the test, when you get some time to read the questions, read them and underline some important keywords in the questions. When you hear those keywords, you will know that you can expect to hear the answers next. It helps you quickly spot your answers especially, when you know you are listening for a date, or a time, or a dish on the menu and so on.
            <br></br><br></br><b>4. Don’t get stuck if you miss an answer</b><br></br>
            When you predicted what kind of answer you are listening for, you can easily figure out when the topic of conversation switches to another subject and it is then when you realise that you missed that answer. At that juncture, you can not let yourself loose your morale and regret, you have to quickly move on the next question, predict the answer type and start listening for it, to avoid a chain reaction causing you to lose multiple answers. Worst thing that you can do is to miss all questions after the ONE THAT YOU MISSED. Most sensible thing is to be extra cautious and getting all rest of the questions correct.
            <br></br><br></br><b>5. Practise listening to different speakers and accents</b><br></br>
            IELTS Listening test recordings include speakers from different countries with different accents: they use British, Canadian, American, Australian, New Zealand speakers, etc. You should practise listening to different diction to get familiar with various speaking styles. It is important to listen to varied topics so that you do not lose interest in the audio only because you are unaware of the subject or you find it extremely boring or difficult.            
            
                            </p>
                        ): parseInt(id.id)===2?(
                            <p>
                                In IELTS exam, reading and listening modules involve CLERICAL SYSTEM OF EVALUATION so if you
get the answers of questions correct, you will get the EXACT SCORE. Hence it is easy to calculate the
score that you could get provided you are sure you did not make any spelling and grammatical errors.
The evaluation of writing and speaking modules works DIFFERENTLY. These are evaluated by the
examiner and the evaluation largely depends on the judgement of the examiner.
There are multiple factors involved that enablean examiner to decide what band he should give to you
in these modules :
<br></br><br></br><b>1. The points covered are RELEVANT to the task or the topic asked.</b>
<br></br><br></br><b>2. The arguments are CONVINCING and support the STAND TAKEN.</b>
<br></br><br></br><b>3. The examples covered are RELEVANT to the argument</b>
<br></br><br></br><b>4. Have you covered the points that HE IS EXPECTING on the topic?</b><br></br><br></br>
Remember you may write very well or speak fluently in English, but if it is not in line with
WHAT IS EXPECTED, how much ever hard you try, you will not achieve the score that you are
aiming for.
<br></br><br></br>It is important to think from an examiner’s perspective, how he would address the topic and
what he expects, so that your STAND and ARGUMENTS are CONVINCING ENOUGH to IMPRESS
the examiner to give you your desired band.
                            </p>
                        ):parseInt(id.id)===3?(
                            <p>
                                PTE exam tests you on all your enabling skills through different question types in all sections :
                                <br></br><br></br><b>1. Grammar (mainly reading and writing)</b>
                                <br></br><br></br><b>2. Oral fluency (Mainly speaking and listening)</b>
                                <br></br><br></br><b>3. Pronunciation (Mainly speaking and listening)</b>
                                <br></br><br></br><b>4. Spelling (mainly writing)</b>
                                <br></br><br></br><b>5. Vocabulary ( mainly reading and writing)</b>
                                <br></br><br></br><b>6. Written discourse (mainly writing)</b>
                                <br></br><br></br>All the sections are interlinked and every question type mostly impacts scoring in atleast two sections. This
would help you understand which areas to work on to improve your scores in any particular section.
Sure shot Strategies :
<br></br><br></br>The below tips and tricks would help you get a high score:
<br></br><br></br><b>1. Practise with varied content!</b><br></br>
Practice listening to simple short sentences on diverse topics and diction and repeating them would help you get
familiar with all types of content . If you do not work or study in an English speaking environment or are new to it, you
must get used to listening and speaking English using resources such as TED Talks.
<br></br><br></br><b>2. Emphasize on the meaning</b><br></br>
If you understand the meaning you will be able to repeat the sentence with much more accuracy. Trying to cram or
remember every word on the other hand can become quite taxing and unfruitful.
<br></br><br></br><b>3. Don’t copy the speaker</b><br></br>
Don’t try to imitate the speaker. You should try to understand the tone and try to stress on a point or a word like the
speaker to bring the same expression. You need not bother to mimic the diction, as content and pronunciation is very
important.
<br></br><br></br><b>4. Imagine as you memorize!</b><br></br>
Imagine the content and try to visualize when you hear so that it is easy to take notes to remember the whole
sentence.
<br></br><br></br><b>5. Shorten the sentence</b><br></br>
In this approach you try to write down the first letter of each word or the first few letters of each words. You should
practise this in your mock sessions before you implement in the main exam else you will get confused with the short
forms.
<br></br><br></br><b>6. Brush up your vocabulary</b><br></br>
Go through the Academic word list and make sure you are familiar with the words that are most likely to appear in an
exam like PTE. This will help you easily recognize the words instantly and remember them when you hear a
sentence.
                            </p>
                        ):parseInt(id.id)===4?(
                            <p>
                                The quantitative section in GMAT tests on your ability to crack application based problems that
enable you to deal with the quantitative curriculum in business schools.
GMAT score has a 22% weightage in MBA admissions. Out of this 22%, 16% weightage is
given to your overall GMAT score out of 800 and an additional 6% weightage is given to your
GMAT quant score.
<br></br><br></br>You get 62 minutes to answer 31 questions to answer in the quant section which means you have
only 2 minutes for each question! You have to be strict with your timelines in your practice
sessions so that you do not stress out in the exam. With few important strategies and a lot of
practice, you would definitely be able to use your time effectively aiming at high accuracy.
If you want to ensure you score well and avoid careless mistakes that may reduce your GMAT
quant score, a well structured approach to solving questions is essential where you practice
enough on application of the concepts learnt and apply them correctly in the questions.
<br></br><br></br><b>1. How to begin :</b><br></br>
Your prime focus should be on accuracy. First master the concepts and try to get the questions
correct. As you know, speed comes with accuracy, you will gradually be able to decide faster
where to apply formulae and where to simply apply elimination method using the choices given.
The type of question decides the method of solving the question and you learn this with
extensive practice!
<br></br><br></br><b>2. Analysis of practice questions</b><br></br>
Create a list of errors you make so that you can identify your weak or
improvement areas and take remedial steps to ensure you do not repeat them. Do

not panic if you make mistakes, take it as a step to discovering new areas of
improvement to work on but if you are making same errors, then it is definitely a
problem. You need to stop and rectify before you proceed further in your
preparation.
<br></br><br></br><b>3. Time your practice sessions</b><br></br>
Timed practice with high accuracy is the key to accomplish a high score. As we know, GMAT is
a computer adaptive test, do not focus only on harder questions. It is crucial to master easy
and medium difficulty level questions. You should take sectional tests with a greater mix of
hard GMAT quant questions to ensure that you have sufficient practice of solving them in a
timed environment.
<br></br><br></br>On the D- day, make sure to take care of the following points :<br></br><br></br>
<b>1. It is important to choose the right order of the sections.</b><br></br>
The GMAT exam lasts for 3 hours and 7 minutes and consists of four different sections.
<br></br>- Quantitative Reasoning
<br></br>- Verbal Reasoning
<br></br>- Integrated Reasoning
<br></br>- Analytical Writing Assessment
<br></br><br></br>
GMAT offers 3 different options on how to select your section order for the GMAT. This
selection should be based on your testing preferences and strengths and weaknesses to ensure
you get Q50+ score in GMAT.
<br></br><br></br><b>2. Optimize the time</b><br></br>
You need not spend 2 minutes on every question. Wherever possible, save time especially in the
easy concept based questions with minimal calculations so that you can compensate against more
time spent on harder questions.
<br></br><br></br><b>3. Use elimination method and make calculated guesses</b><br></br>
Being fully prepared for the exam is not enough, it is important to strategize which questions to
attempt and utilize the time effectively to ensure you get a high score. Spending a lot of time on
the questions that you think are really difficult, lengthy, or both is not an advisable approach.
If you think a question will take too much time, you should do the following:
<br></br>- Use elimination method using the options
<br></br>- Since there is no negative marking in GMAT, make a calculated guess , mark the answer
and move on.
                            </p>
                        ):parseInt(id.id)===5?(
                            <p>
                                The GRE max score is 340 (the GRE total score ranges from 260-340). The
essay/analytical writing score (AWA) runs from 0.0-6.0 in half-point increments.
Every graduate school has a different scoring criteria so deciding on the university that
one aspires to go to, can help one set a target score in the exam.
For many graduate schools, it is suffice to score a 75th percentile or above: 157+ in
Verbal Reasoning and 161+ in Quantitative Reasoning. For top programs, it’s a 90th
percentile score or higher: 162+ in Verbal and 168+ in Quant.
Many candidates find quantitative section a piece of cake being engineering students (aspiring to
go for Masters in their discipline) while they are worried about the GRE verbal section . It is not
enough being simply fluent or well conversant with the language, it is important to strategize the
preparation to achieve high accuracy.
<br></br><br></br>The GRE verbal section is broken down into three different components:<br></br><br></br>
<b>1. Text Completion :</b><br></br> To fill in the blanks of a sentence with the right word so that
the sentence is meaningful.
<br></br><br></br><b>2. Sentence Equivalence :</b><br></br> To select two words from a list of six that correctly fit
the sentence making it relevant and valid.
<br></br><br></br><b>3. Reading Comprehension :</b><br></br> This involves students to answer comprehensive
questions based on short passages. This is the most critical section and very important if one
needs to achieve high score.
<br></br><br></br>Some tips to help you boost your verbal score are :
 
<br></br><br></br>1. Learning words in groups is useful. The most effective method is to learn the root words, using
etymology to learn a huge range of words is beneficial to expand your vocabulary.
<br></br><br></br>2. For text completion type of questions, eliminate the options you are sure would not fit in the
answer, using smart ways to choose the right answer from the remaining options.
<br></br><br></br>3. It is important to read on varied topics to be familiar with all kinds of content. Voracious
readers definitely have an edge, yet reading skills can be improved by using effective skimming
and mapping techniques on passages on diverse topics.
<br></br><br></br>4. Timed practice with high accuracy is the key to accomplish a high score. It is important to do a
lot of practice and try achieve accuracy in a timed environment.
                            </p>
                        ):(
                            null
                        )
                    }
                    </p>
                </div>

                </div>

            </div>
          </div>
          <div className="col-lg-4">
          <BlogRightAd/>
          </div>
        </div>
      </div>
      </>
    );

}

export default BlogView;