import React from 'react'
import {ExpertEnglishNavigation} from "../../Products/ExpertEnglish/ExpertEnglishNavigation";
import AccessibilityNewOutlinedIcon from "@material-ui/icons/AccessibilityNewOutlined";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const PricingPageForSAT = ({name, registerLink, title}) => {

    return (
        <div>
            <ExpertEnglishNavigation name={name} registerLink={registerLink}/>
            <div className={'pricing-page'}>
                <p className={'pricing-page-title'}>{title ? title : `Improve your ${name} score today by atleast 10 points! Guaranteed!`}</p>
                <p className={'pricing-page-subtitle'}>Our exhaustive content + Your commitment = Your Dream Score</p>
                <div className={'pricing-page-content-title'}>Here's what you get:</div>
                <div className={'pricing-page-content'}>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <AccessibilityNewOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>DETAILED SAT PREP</p>
                            <p>All materials for the verbal and quant sections are diligently created by our leading SAT experts. Study anywhere, anytime, on any device.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <OndemandVideoOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>OVER 200 VIDEO LESSONS</p>
                            <p>Learn new skills and strategies on various concepts focusing on effective application of these in practice questions, thereby enhancing your SAT score.
                            </p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <ChatBubbleOutlineOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>PRACTICE TESTS</p>
                            <p>Time yourself taking practice tests and detailed feedback on each question that would help you understand your improvement areas and work on them.
</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <AccountCircleOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>STUDY SCHEDULE</p>
                            <p>Coordinators would help you design your perfect study schedule based on your needs helping you to stay on track and motivated to accomplish your career goals. HOMEWORKS are regularly given and assessed.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <CheckCircleOutlineOutlinedIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>REGULAR AND CONSISTENT FEEDBACK</p>
                            <p>Weekly sessions with the tutor would keep you engaged and focused! Constant personalized analysis on strengths and weaknesses helps to improve performance.</p>
                        </div>
                    </div>
                    <div className={'pricing-page-item'}>
                        <div className={'second-banner-icon'}>
                            <div>
                                <MailOutlineIcon className={'second-banner-icon-item'}/>
                            </div>
                        </div>
                        <div className={'pricing-page-text'}>
                            <p className={'pricing-page-text-title'}>24/7 EMAIL SUPPORT</p>
                            <p>Quick, helpful, email support from our SAT Experts, whenever you get stuck or just want some extra help.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}