import React from "react";

export const Universities = () => {
    return (
        <div className={'universities-section'}>
            <p className={'page-title'}><span className={'sub-heading'}>Our Alumni</span><br />Our Alumni now in prestigious universities,<br></br> have made us proud</p>
            <div className={'university-first-row'}>
            <img alt="University selection of testacademia" style={{height: '110px'}} src={'https://medievalartresearch.files.wordpress.com/2020/09/the-university-of-chicago-.png'}/>
                <img alt="University selection of testacademia" style={{height: '110px'}} src={'https://seekvectorlogo.com/wp-content/uploads/2018/02/university-of-toronto-vector-logo.png'}/>
                <img alt="University selection of testacademia" style={{height: '70px'}} src={'https://engineering.purdue.edu/ECE/Communications/files/PU-V-Full-RGB.png'}/>
                <img alt="University selection of testacademia" style={{height: '110px'}} src={'https://seekvectorlogo.com/wp-content/uploads/2018/01/the-university-of-sydney-vector-logo.png'}/>
            </div>
            <div className={'university-second-row'}>
                <img alt="University selection of testacademia" src={'https://static.wixstatic.com/media/6b6ad5_9af93df267524b57b3800225c7e84dd3~mv2.png/v1/fill/w_207,h_207/6b6ad5_9af93df267524b57b3800225c7e84dd3~mv2.png'}/>
                <img alt="University selection of testacademia" src={'https://upload.wikimedia.org/wikipedia/en/thumb/8/8b/American_University_Seal.svg/1200px-American_University_Seal.svg.png'}/>
                <img alt="University selection of testacademia" src={'https://www.clarivate.ru/wp-content/uploads/2017/04/university-of-melbourne-logo.jpeg'}/>
                <img alt="University selection of testacademia" src={'https://upload.wikimedia.org/wikipedia/commons/c/c8/Logo_of_Stanford_University.png'}/>
            </div>
        </div>
    )
}