import React from 'react';
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {ProductBanner} from "../../common/ProductBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {InfoBanner} from "../../common/InfoBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {Migration} from "../../common/Migration";
import {Universities} from "../../common/Universities";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";

export const PTE = () => {
    const data = {
        text: 'Improve Your Overall PTE by atleast 10 points with our world class test prep!',
        img: 'https://ugc.futurelearn.com/uploads/images/e0/01/thumbnail_e0014871-0839-4fae-bb4f-38a9e6eae597.jpg   ',
        link: '/PTE-pricing',
        name: 'PTE',
        registerLink: 'https://pte.testacademia.com/',
        title: 'Avail the world\'s best PTE Test Prep! Impactful! Price worthy! Excellent Results!'
    }

    const text = [
        {
            title: "Structured curriculum",
            text: "In depth engaging video lessons by leading PTE experts with simple instructions and effective tricks and strategies for a high score. Stay focused!",
        },
        {
            title: "High quality Content",
            text: "1000+ practice questions and AI scored full length tests close to official PTE exam",
        },
        {
            title: "Progress tracking",
            text: "The smart dashboard gauges your capabilities, predicts your levels of performance and the score, close to the actual one.",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <ProductBanner type={'pte'} data={data}/>
            <WhyWeBanner text={text} name={data.name}/>
            <FourthBanner/>
            <Universities/>
            <Migration/>
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}