import React, {useEffect, useState} from "react";
// import {PricingPage} from "../../common/PricingPage";
// import {PricingQuestions} from "../../common/PricingQuestions";
import {PricingPageForGMAT} from "../../common/PricingPages/PricingPageForGMAT";
import {PricingQuestionsForGMAT} from "../../common/PricingQuestionsPages/PricingQuestionsForGMAT";
import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
import {Preloader} from "../../common/Preloader";

export const GMATPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/GMAT-register'
    const name = 'GMAT'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/gmat/package/bannerpackage')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>
    return (
        <div>
            <PricingPageForGMAT name={name} registerLink={'https://gmat.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                                  link={link}
                                                                  key={item.price}/>)}
            </div>
            <PricingQuestionsForGMAT/>
        </div>
    )
}