import React from "react";
import {Navigation} from "./Navigation";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import firstFlow from '../../img/flow-1.jpg'
import secondFlow from '../../img/flow-2.jpg'

export const IELTSTest = () => {
    return (
        <div>
            <Navigation/>
            <div className={'ielts-test test-img'}>
                <a href= "/IELTS-pricing" >
                    <img alt="Ielts Online Course"
                         src={'https://ptetutorials.com/images/iPhoneImages/SMPost_Scored_UnscoredPractice_Tests.jpg'}/>
                </a>
                <div className={'test-text'}>
                    <p><span>IELTS Practice Tests</span> enable you
                        to <span>experience real time test environment</span> as that of the actual
                        IELTS-A test. The tests are cloud-based and thus, <span>accessible online</span>.
                        This gives you flexibility and convenience to <span>practice anytime, anywhere.</span></p>
                    <p>Since it is a computer-based test, they are timed just like the real IELTS-A test so that you
                        become acquainted as well as be alert about the ticking time. With our <span>Scored Practice Tests</span>,
                        you can anticipate your <span>score on a Global Scale of English</span> (0-9) and also
                        <span> check your sub-skills</span> for all the four modules.</p>
                    <p>Further, IELTS practice tests also help you adapt to the difficulties and challenges that you
                        will face in the real exam.</p>
                </div>
            </div>
            <div className={'test-heading'}>
                <p>Types of Practice Tests We Offer</p>
            </div>
            <div className={'test-content'}>
                <div className={'test-item'}>
                    <p className={'test-item-title'}>SCORED PRACTICE TESTS</p>
                    <span>Scored Practice Tests familiarize you with actual IELTS-A Test.
                        It helps you gauge your performance for the actual test. Further,
                        it also provides you an indication about your calibre of all the
                        English language skills required in IELTS-A Exam.</span>
                    <div className={'benefits'}>
                        <p>Benefits</p>
                    </div>
                    <div className={'benefits-content'}>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>Test evaluation is done by experienced and expert IELTS Assessors adhering to IELTS
                                Scoring guidelines.</p>
                        </div>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>All the four modules : Listening, Reading, Writing and Speaking are scored as per the
                                scoring criteria defined by IELTS examination guidelines.</p>
                        </div>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>Writing and Speaking assessment covers detailed explanation on your performance with
                                respect to various evaluation parameters with a BAND RANGE on your tasks with above 95%
                                accuracy.</p>
                        </div>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>Once the Practice Test is assessed, you can access the Test Analytics in your Test Panel.
                                Know number of questions you attempted, the question formats that you need to work on in
                                every module, your weak areas and improvement areas with these analytics.</p>
                        </div>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>Scored Practice Tests let you examine the improvement in your overall performance with
                                Test Comparison</p>
                        </div>
                        <img alt="Ielts course Flow" className={'flow-image1'} src={firstFlow}/>
                    </div>
                </div>
                <div className={'test-item'}>
                    <p className={'test-item-title'}>UNSCORED PRACTICE TESTS</p>
                    <span>Take IELTS Unscored Practice Tests and experience an interface similar to the actual IELTS Exam. Get
                        to know the type of questions asked in all the four modules, learn time management and gain
                        advantage of model answers & explanations for the same.</span>
                    <div className={'benefits'}>
                        <p>Benefits</p>
                    </div>
                    <div className={'benefits-content'}>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>After completion of your test, view time-based test analytics of attempted test. Know
                                number of questions you attempted, the question formats that you need to work on in
                                every module, your weak areas and improvement areas with these analytics.</p>
                        </div>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>Listening and Reading modules will be evaluated and scored. However, writing and speaking
                                sections are unscored.</p>
                        </div>
                        <div className={'benefits-item'}>
                            <ArrowRightIcon className={'benefit-icon'}/>
                            <p>If you want to get your Unscored Practice test evaluated, you can purchase evaluation
                                package separately and choose which tests you want to get checked especially for writing
                                and speaking modules.</p>
                        </div>
                        <img alt="Ielts online course Flow" className={'flow-image2'} src={secondFlow}/>
                    </div>
                </div>
            </div>
        </div>
    )
}