import React, {useState} from "react";
import {Navigation} from "./Navigation";
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import {MobileNavigation} from "./MobileNavigation";
import LaptopOutlinedIcon from '@material-ui/icons/LaptopOutlined';
import CastForEducationOutlinedIcon from '@material-ui/icons/CastForEducationOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';

export const Admission = () => {
    const [visibility, setVisibility] = useState(false)

    return (
        <div className={'admission-page'}>
            <MobileNavigation setVisibility={setVisibility} visibility={visibility}/>
            <Navigation setVisibility={setVisibility}/>
            <p className={'about-us-title admission-title'}>Admission Counselling</p>
            <div className={'admission-page-content'}>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <SubjectOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>University Selection</p>
                    <p className={'admission-text'}>Your profile evaluation will help you understand your requirements and recommend the list of universities that you should apply to.</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <AccessibilityNewOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>SOP / LOR</p>
                    <p className={'admission-text'}>Our team of experienced writers assist you completely with your Statement of purpose and letters of recommendation. They review your writings and their suggestions help you to improvise as per the requirements of each university.</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <ThumbUpAltOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Visa Processing</p>
                    <p className={'admission-text'}>Right guidance on all documentation and formalities ensures least lead time and 100% success for each student . Our experts conduct mock visa interviews with invaluable tips boosts the confidence in acing the visa interview!</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <LaptopOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Progress tracking</p>
                    <p className={'admission-text'}>You will have a dedicated coordinator evaluating your performances and motivating you towards your goal!</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <CastForEducationOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Education Loan</p>
                    <p className={'admission-text'}>Our team avails the needed assistance to fetch you education loans from various banks. Throughout the process, we provide guidance and there is no fee of any kind for processing.</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <VerifiedUserOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Review of Essays and Resume</p>
                    <p className={'admission-text'}>Our team helps you in review of all admission and scholarship essays and try our best that you get your dream university at an affordable price!</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <GavelOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Insurance</p>
                    <p className={'admission-text'}>For insurance of any type, we are the best people to rely on. We have enough associations with multiple insurance service providers that help us to provide you coverage at a cheaper rates.</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <SupervisedUserCircleOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Complimentary Services</p>
                    <p className={'admission-text'}>We facilitate DHL courier services and ensure hassle- free and swift Forex services.</p>
                </div>
                <div className={'admission-page-item'}>
                    <div className={'admission-icon-box'}>
                        <SchoolOutlinedIcon className={'admission-icon'}/>
                    </div>
                    <p className={'admission-title'}>Accomodation Assistance</p>
                    <p className={'admission-text'}>Our team ensures that with the quality accommodation service suppliers, you discover a home that goes well together with your budget and fashion. Making you feel at home!</p>
                </div>
            </div>
        </div>
    )
}