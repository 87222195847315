import React from 'react'

export const PricingQuestionsForExpertEnglish = () => {
    return (
        <div className={'pricing-questions'}>
            <p className={'pricing-questions-title'}>Frequently Asked Questions</p>
            <div className={'pricing-question-content'}>
                <div className={'pricing-row'}>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>How will the course be useful?</p>
                        <p className={'pricing-question-text'}>The course is structured to help you work on your English right from the basics. Detailed grammar lessons will highlight on every concept with exercises. It will help you feel better-equipped and gain the confidence you need to face in the corporate world or in any competitive exams and more.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What are the requirements ?</p>
                        <p className={'pricing-question-text'}>All you will need is some familiarity with English language being the teaching medium. You will start from the basics of grammar and we will help you improve all the way up to mastering professional English.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What will be my study plan?</p>
                        <p className={'pricing-question-text'}>After enrolment, you will be givena preliminary Test, you’ll receive a comprehensive Study Plan that will assign your daily tasks and guide you through the course step-by-step.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What next after the payment?</p>
                        <p className={'pricing-question-text'}>You will receive a Mail (within a day) welcoming you to join the Testacademia Family!
You will get a study plan with all the necessary credentials and resources.
You will be the first to hear about new products and special offers.
</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is safe. Do not worry!
If you have trouble paying, email support@testacademia.com, testacademiaglobal@gmail.com or whatsapp , we will get back to you asap.
</p>
                    </div>
                </div>
                {/* <div className={'pricing-row'}>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Do I need to have taken the test before?</p>
                        <p className={'pricing-question-text'}>No! Many of our students are first-time GRE test-takers, so if you're ready to start studying now, you should go ahead and sign up! Our lessons start from the beginning and teach you everything you need to know about the PTE, so taking the test previously is not required.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is secure. Do not worry!
                            If you are facing any trouble, please contact at support@testacademia.com or whatsapp at 9591722199</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>What types of payment do you accept?</p>
                        <p className={'pricing-question-text'}>You can pay through any mode : debit / credit card or netbanking. All your payment transactions are secure and credit card information is safe. Dont worry!</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Can I purchase multiple accounts?</p>
                        <p className={'pricing-question-text'}>You definitely can! Email us at support@testacademia.com and we'll help set you up.</p>
                    </div>
                    <div className={'pricing-question-item'}>
                        <p className={'pricing-question-title'}>Do you have iPad/iPhone/Android access?</p>
                        <p className={'pricing-question-text'}>Yes, we do! You can access all of the lessons anywhere with our apps, and you can use your mobile browser to get full access to the site.</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}