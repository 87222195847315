import React from 'react';
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {ProductBanner} from "../../common/ProductBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {InfoBanner} from "../../common/InfoBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {Universities} from "../../common/Universities";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";

export const TOEFL = () => {
    const data = {
        text: 'You are a step away from your dream university!',
        img: 'https://ugc.futurelearn.com/uploads/images/e7/d9/thumbnail_e7d90dd5-456e-4859-bb6f-a34422391f8b.jpg',
        link: '/TOEFL-pricing',
        name: 'TOEFL',
        registerLink: 'https://toefl.testacademia.com',
        title: 'Best world class TOEFL prep! Bridge the gap between you and your admission today!'
    }

    const text = [
        {
            title: "Structured curriculum",
            text: "In depth engaging video lessons by leading TOEFL experts with simple instructions and strategies for a high score. Stay focused!",
        },
        {
            title: "Updated TOEFL pattern",
            text: "Practice questions, video lessons and AI scored full length tests close to official TOEFL exam",
        },
        {
            title: "Progress tracking",
            text: "The smart dashboard gauges your capabilities, predicts your levels of performance and the score, close to the actual one",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <ProductBanner title={data.title} type={'toefl'} data={data}/>
            <WhyWeBanner small={true} name={data.name} text={text}/>
            <FourthBanner/>
            <Universities/>
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}