import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import React from "react";
import {Video} from "../Home/Banners/Video";

export const WhyWeBanner = (props) => {
    const name = props.name
    const text = props.text
    const small = props.text

    return (
        <div className={'third-banner'}>
            <p className={'third-banner-title'}>Cross Platform Access</p>
            <div className={'third-banner-big'} style={small ? {justifyContent: 'center'} : undefined}>
                <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <SubjectOutlinedIcon className={'third-banner-icon'}/>
                    </div>
                    <p className={'third-banner-item-title'}>{text[0].title}</p>
                    <p className={'third-banner-item-subtitle'}>{text[0].text}</p>
                </div>
                <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <ThumbUpAltOutlinedIcon className={'third-banner-icon'}/>
                    </div>
                    <p className={'third-banner-item-title'}>{text[1].title}</p>
                    <p className={'third-banner-item-subtitle'}>{text[1].text}</p>
                </div>
                {small ? <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <CheckBoxOutlinedIcon className={'third-banner-icon'}/>
                    </div>
                    <p className={'third-banner-item-title'}>{text[2].title}</p>
                    <p className={'third-banner-item-subtitle'}>{text[2].text}</p>
                </div> : undefined}
                <div className={'third-banner-item-small'}>
                    <div className={'third-banner-icon'}>
                        <SmartphoneOutlinedIcon className={'third-banner-icon'}/>
                    </div>
                    <p className={'third-banner-item-title'}>Convenient & Flexible</p>
                    <p className={'third-banner-item-subtitle'}>Study any time, anywhere, with 24/7 online access.
                        Hectic work schedules WON’T get in the way of a high {name} score anymore! Practice on the go
                        with our FREE Android & iOS apps.</p>
                </div>
            </div>
        </div>
    )
}