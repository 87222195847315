import React from 'react';
import {Link} from "react-router-dom"

export const Navigation = ({setVisibility}) => {
    return (
        <div className={'header'}>
            <header className="header-container">
                <Link to="/">
                    <div className="navbar-brand logo_h">
                        <img alt="TestAcademia Logo" src={require("../../img/logo.jpg")} alt=""/>
                    </div>
                </Link>
                <ul className="header-navigation">
                    <li className="nav-item product-list">
                        <a to="#" id={'blink'}>
                            <div className="nav-link product-list-head">Courses</div>
                        </a>
                        <div className={'dropdown-nav'}>
                            <Link to={'/IELTS'}>IELTS</Link>
                            <Link to={'/PTE'}>PTE</Link>
                            <Link to={'/GRE'}>GRE</Link>
                            <Link to={'/TOEFL'}>TOEFL</Link>
                            <Link to={'/GMAT'}>GMAT</Link>
                            <Link to={'/SAT'}>SAT</Link>
                            <Link to={'/OET'}>OET</Link>
                            <Link to={'/expert-english'}>Expert English</Link>
                        </div>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="/IELTS-tests">
                            <div className="nav-link">IELTS Mock Tests</div>
                        </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/government-exams" id={'blink'}>
                            <div className="nav-link">Government Exams</div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/expert-english" id={'blink'}>
                            <div className="nav-link">English Expert</div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/testimonials">
                            <div className="nav-link">Testimonials</div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about-us">
                            <div className="nav-link">About Us</div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/blog">
                            <div className="nav-link">Blog</div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact">
                            <div className="nav-link">Contact</div>
                        </Link>
                    </li>
                    <li className={'nav-item product-list'}>
                        <a to="#" id={'blink'}>
                            <div className="nav-link product-list-head">Free Apps</div>
                        </a>
                        <div className={'dropdown-nav'}>
                            <a href="https://play.google.com/store/apps/details?id=com.testacademia.ielts">
                                Testacademia
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app">
                                Crucibledesk
                            </a>
                        </div>
                    </li>
                </ul>
                <div className={'mobile-navigation'}>
                    <div className={'hamburger'} onClick={() => setVisibility(true)}>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
            </header>
        </div>
    )
}