import React, {useState} from 'react';
import {SecondBanner} from './Banners/SecondBanner';
import {Navigation} from "../common/Navigation";
import {Banner} from './Banners/Baner';
import {ThirdBanner} from './Banners/ThirdBanner';
import {FourthBanner} from './Banners/FourthBanner';
import {ReviewSlider} from "./ReviewSlider";
import {MobileNavigation} from "../common/MobileNavigation";

export const Home = () => {
    const [visibility, setVisibility] = useState(false)

    return (
        <div>
            <MobileNavigation setVisibility={setVisibility} visibility={visibility}/>
            <Navigation setVisibility={setVisibility}/>
            <Banner/>
            <SecondBanner/>
            <ThirdBanner/>
            <FourthBanner/>
            <ReviewSlider/>
        </div>
    )
}