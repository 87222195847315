import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {Link} from "react-router-dom";
import React, {useState} from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export const MobileNavigation = ({visibility, setVisibility}) => {
    const [mobileNav, setMobileNav] = useState(false)
    const [dropdown, setDropdown] = useState(false)

    return (
        <div className={'header-mobile-nav'}
             style={visibility ? {right: 0, transition: '0.3s'} : {right: '-100%', transition: '0.3s'}}>
            <div className={'close-icon-item'} onClick={() => setVisibility(false)}>
                <CloseOutlinedIcon className={'close-icon'}/>
            </div>
            <ul>
                <li>
                    <Link to={'/expert-english'}>Expert English</Link>
                </li>
                <li>
                    <Link to={'/government-exams'}>Government Exams</Link>
                </li>
                <li>
                    <Link to={'/about-us'}>About us</Link>
                </li>
                <li>
                    <Link to={'/testimonials'}>Testimonials</Link>
                </li>
                <li>
                    <div className={'nav-mob-title'} onClick={() => {
                        mobileNav ? setMobileNav(false) : setMobileNav(true)
                    }}>
                        <p>Products</p>
                        {mobileNav ? <ArrowDropUpIcon className={'nav-icon-arrow'}/> :
                            <ArrowDropDownIcon className={'nav-icon-arrow'}/>}
                    </div>
                    {mobileNav && <div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/IELTS'}>IELTS</Link>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/TOEFL'}>TOEFL</Link>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/GMAT'}>GMAT</Link>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/PTE'}>PTE</Link>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/GRE'}>GRE</Link>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/SAT'}>SAT</Link>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <Link to={'/OET'}>OET</Link>
                        </div>
                    </div>
                    }
                </li>
                <li>
                    <Link to={'/blog'}>Blog</Link>
                </li>
                <li>
                    <Link to={'/contact'}>Contact</Link>
                </li>
                <li>
                    <div className={'nav-mob-title'} onClick={() => {
                        dropdown ? setDropdown(false) : setDropdown(true)
                    }}>
                        <p>Free Apps</p>
                        {dropdown ? <ArrowDropUpIcon className={'nav-icon-arrow'}/> :
                            <ArrowDropDownIcon className={'nav-icon-arrow'}/>}
                    </div>
                    {dropdown && <div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <a className={'lowercase'} href="https://play.google.com/store/apps/details?id=com.testacademia.ielts">
                                Testacademia
                            </a>
                        </div>
                        <div className={'nav-item-mob'}>
                            <ArrowRightIcon className={'nav-icon-arrow'}/>
                            <a className={'lowercase'} href="https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app">
                                Crucibledesk
                            </a>
                        </div>
                    </div>
                    }
                </li>
            </ul>
        </div>
    )
}