import React from "react";
import axios from "axios";
import cookie from "react-cookies";
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {Address} from "../ExpertEnglish/Address";
import {exportresponserazorpay} from "../../common/razorpayfunction";

export default class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            mobile: '',
            password: '',
            course: '',
            u: '',
            pack: this.props.location.state.package_val,
        }

        this.onChangename = this.onChangename.bind(this);
        this.onChangeemail = this.onChangeemail.bind(this);
        this.onChangemobile = this.onChangemobile.bind(this);
        this.onChangepassword = this.onChangepassword.bind(this);
        this.onregister = this.onregister.bind(this);
        this.openPayModal = this.openPayModal.bind(this);
        this.responserazorpay = this.responserazorpay.bind(this);

        this.options = {
            "key": process.env.REACT_APP_razorpay_id,
            "amount": 0, // 2000 paise = INR 20, amount in paisa
            "name": "Aspire Testacademia",
            "description": "",
            'order_id': "",
            "handler": this.responserazorpay,
            "prefill": {
                "name": this.state.u.name,
                "email": this.state.u.email,
                "contact": this.state.u.mobile,
            },
            "theme": {
                "color": "#528ff0"
            }
        };
    }

    responserazorpay(response){
        exportresponserazorpay(response,this.state.u,this.state.pack);
    }

    componentDidMount() {
        console.log(this.state.pack)
        const script = document.createElement("script");

        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;

        document.body.appendChild(script);

    }

    onChangename(e) {
        //console.log(this.props.location.state.discountprice)
        this.setState({
            name: e.target.value,
        });
    }

    onChangeemail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangemobile(e) {
        this.setState({
            mobile: e.target.value
        });
    }

    onChangepassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onregister(e) {
        e.preventDefault();

        const newUser = {
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            password: this.state.password,
            course: "PTE",
        }
        console.log(newUser);
        axios.post('https://api.testacademia.com/user/pte/auth/register', newUser)
            .then(res => {
                console.log(res.data)
                this.setState({
                    u: res.data
                })

                cookie.save('userid', res.data._id, {path: "/", maxAge: 10800})
                if (res.data !== 'Email already exists') {
                    this.openPayModal(this.props.location.state.discountedprice)
                } else {
                    alert(res.data);
                }

            })
            .catch(e => {
                alert(e)
            })

    }

    openPayModal(amt) {
        var amount = amt * 100;
        console.log(amount);

        axios.post('https://api.testacademia.com/user/pte/upgrade/order', {amount: amount})
            .then(res => {
                this.options.order_id = res.data.id;
                this.options.amount = res.data.amount;
                console.log(this.options)
                var rzp1 = new window.Razorpay(this.options);
                rzp1.open();
            })
            .catch(e => console.log(e))


        this.setState({
            name: '',
            password: '',
            email: '',
            mobile: '',
            course: null
        })
    };

    render() {
        return (
            <div className="enquiry-form-area">
                <ExpertEnglishNavigation registerLink={'https://pte.testacademia.com/'}/>
                <div className="enquiry-form-area">
                    <div className="equiry-heading">
                        <p className={'contact-form-title'}>Success is just a click away!</p>
                        <p className={'contact-form-subtitle'}>Take a plunge! Indulge. Improvise ! Succeed!</p>
                        <div className="form-area">
                            <div className={'register-page'}>
                                <tr>
                                    <td>
                                        <div>
                                            <input className={'register-input'}
                                                   type="text"
                                                   name="name"
                                                   value={this.state.name}
                                                   id="exampleEmail"
                                                   placeholder="Enter Name"
                                                   onChange={this.onChangename}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <input className={'register-input'}
                                                   type="email"
                                                   name="email"
                                                   value={this.state.email}
                                                   id="exampleEmail"
                                                   placeholder="Enter Email"
                                                   onChange={this.onChangeemail}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <input className={'register-input'}
                                                   type="text"
                                                   name="mobile"
                                                   value={this.state.mobile}
                                                   id="exampleEmail"
                                                   placeholder="Enter Phone No."
                                                   onChange={this.onChangemobile}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <input className={'register-input'}
                                                   type="password"
                                                   name="password"
                                                   value={this.state.password}
                                                   id="examplepassword"
                                                   placeholder="Enter Password"
                                                   onChange={this.onChangepassword}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <button className="explore-plan-btn" color="primary" type="button"
                                                onClick={this.onregister}>
                                            Pay Now
                                        </button>
                                    </td>
                                </tr>
                            </div>
                        </div>
                    </div>
                </div>
                <Address/>
            </div>
        )
    }
}