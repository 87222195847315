import React from 'react';
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {ProductBanner} from "../../common/ProductBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {InfoBanner} from "../../common/InfoBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {Migration} from "../../common/Migration";
import {Universities} from "../../common/Universities";
import {MobileApp} from "../../Home/Banners/MobileApp";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";

export const IELTS = () => {
    const data = {
        text: 'Strategize your preparation and Optimize your potential!',
        img: 'https://ugc.futurelearn.com/uploads/images/c1/0a/thumbnail_c10a541a-300d-4542-9ef3-54c9d7602db9.jpg',
        link: '/IELTS-pricing',
        name: 'IELTS',
        type: 'oet',
        registerLink: 'https://ielts.testacademia.com/',
    }

    const text = [
        {
            title: "Real time IELTS TESTS",
            text: "Get atleast 10 full length and 30 sectional tests on exact test pattern\nwith AI based assessments upto 98% accuracy!",
        },
        {
            title: "Progress Tracking",
            text: "You will be assigned a dedicated coordinator evaluating your\nperformance and motivating you towards your goal!",
        },
        {
            title: "2000+ Practice Questions",
           // text: "The smart dashboard gauges your capabilities, predicts your levels of performance and the score, close to the actual one.",
           text:"Latest and repeated updated set of practice questions! Be fully prepared for the real exam!"
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <ProductBanner video={true} type={data.type} data={data}/>
            <WhyWeBanner text={text} name={data.name}/>
            <FourthBanner/>
            <Universities/>
            <Migration/>
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}