import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Navigation} from './Navigation';
import axios from "axios";
import {MobileNavigation} from "./MobileNavigation";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

export const Contact = () => {
    const {register, handleSubmit, errors} = useForm()
    const [visibility, setVisibility] = useState(false)
    const onSubmit = data => {
        axios.post('https://api.testacademia.com/enquiry/addenquiry', data).then(result => alert(result.data))
    }

    return (
        <div>
            <MobileNavigation setVisibility={setVisibility} visibility={visibility}/>
            <Navigation setVisibility={setVisibility}/>
            <div className={'contact-form'}>
                <p className={'contact-form-title'}>Success is just a click away!</p>
                <p className={'contact-form-subtitle'}>Take a plunge! Indulge. Improvise ! Succeed!</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <li>
                        <input name="name" placeholder={'Name'} ref={register({required: true})}/>
                        {errors.name && <span>This field is required</span>}
                    </li>
                    <li>
                        <input placeholder={'Email'} name="email" ref={register({required: true})}/>
                        {errors.email && <span>This field is required</span>}
                    </li>
                    <li>
                        <input name="phone" placeholder={'Phone'} ref={register({required: true})}/>
                        {errors.phone && <span>This field is required</span>}
                    </li>
                    <li>
                        <input name="subject" placeholder={'Subject'} ref={register({required: true})}/>
                        {errors.subject && <span>This field is required</span>}
                    </li>
                    <li>
                        <input placeholder={'Message'} name="message" ref={register({required: true})}/>
                        {errors.message && <span>This field is required</span>}
                    </li>
                    <button type="submit">Send Message</button>
                </form>
                <div className={'address'}>
                    <div>
                        <div className={'address-icon'}>
                            <CallOutlinedIcon className={'address-icon-item'}/>
                        </div>
                        <p>Phone number: +91 9591722199</p>
                    </div>
                    <div>
                        <div className={'address-icon'}>
                            <WhatsAppIcon className={'address-icon-item'}/>
                        </div>
                        <p>Whatsapp: +91 9591722199</p>
                    </div>
                    <div>
                        <div className={'address-icon'}>
                            <MailOutlineIcon className={'address-icon-item'}/>
                        </div>
                        <p>Email: support@testacademia.com, testacademiaglobal@gmail.com</p>
                    </div>
                    <div>
                        <div className={'address-icon'}>
                            <HomeOutlinedIcon className={'address-icon-item'}/>
                        </div>
                        <p>Address: 13, 14 Marathahalli, Bengaluru, Karnataka: 560037</p>
                    </div>
                    <div>
                        <div className={'address-icon'}>
                            <ScheduleOutlinedIcon className={'address-icon-item'}/>
                        </div>
                        <p>Schedule: Monday – Sunday 10:00 AM to 08:00 PM IST</p>
                    </div>
                </div>
            </div>
        </div>
    )
}