import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {Link} from "react-router-dom";
import TelegramIcon from "@material-ui/icons/Telegram";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import React from "react";

export const UserInteractive = () => {
    const facebook = 'https://www.facebook.com/'
    const instagram = 'https://www.instagram.com/'
    const telegram = 'https://t.me/'
    const whatsapp = 'https://wa.me/919591722199'
    const twitter = 'https://twitter.com/'

    return (
        <>
            <div className={'whatsapp-icon'} id={'whatsapp'}>
                <a target={'_blank'} href={whatsapp}>
                    <WhatsAppIcon className={'whats-app-icon'}/>
                </a>
            </div>
            <div className={'call-me-back'}>
                <p>
                    <Link to={'/contact'}>Feedback / Support</Link>
                </p>
            </div>
            <div className={'right-side-socials'}>
                <a target={'_blank'} href={instagram}>
                    <div id={'instagram'}>
                        <InstagramIcon className={'link-icon-right'}/>
                    </div>
                </a>
                <a target={'_blank'} href={twitter}>
                    <div id={'twitter'}>
                        <TwitterIcon className={'link-icon-right'}/>
                    </div>
                </a>
                <a target={'_blank'} href={facebook}>
                    <div id={'facebook'}>
                        <FacebookIcon className={'link-icon-right'}/>
                    </div>
                </a>
            </div>
        </>
    )
}