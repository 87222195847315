import React from 'react'
import {Link} from "react-router-dom";

export const InfoBanner = ({link, name}) => {
    return (
        <div className={'info-banner'}>
            <div className={'info-banner-content'}>
                <div className={'info-banner-first'}>
                    <p className={'info-banner-title'}>Prep with only top-quality materials</p>
                    <p className={'info-banner-text'}>Our content is meticulously designed to impart the deep subject
                        knowledge in which students get an immersive learning experience coupled with custom practices
                        such as question wise, module wise and full length preparation. Our analytics algorithms predict
                        your level of competency as close to the real exam as possible.</p>
                    <Link to={link}>Start {name} prep today</Link>
                </div>
                <div className={'info-banner-second'}>
                    <img alt="Start Preparation to study in foreign Universities" src={'https://static.vecteezy.com/system/resources/previews/001/269/525/non_2x/close-up-of-student-studying-free-photo.jpg'}
                         style={{marginLeft: "30px"}}/>
                </div>
            </div>
        </div>
    )
}