import React from 'react'
import {Link} from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowRightAlt';

export const Banner = () => {

    return (
        <div className={'banner-area'}>
            <div className={'banner'}>
                <div className={'banner-first-section'}>
                    <div>
                    <p>Build a career with transformative learning solutions.</p>
                    <p className={'home-subtitle'}>Learn from best teachers and improve with AI - based analytics to crack your exam</p>
                    </div>
                    <div>
                        <div>
                            <Link to="/IELTS">
                                <div className="course-button">
                                    <p>IELTS</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/PTE">
                                <div className="course-button">
                                    <p>PTE</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/GRE">
                                <div className="course-button">
                                    <p>GRE</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/GMAT">
                                <div className="course-button">
                                    <p>GMAT</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/TOEFL">
                                <div className="course-button">
                                    <p>TOEFL</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/SAT">
                                <div className="course-button">
                                    <p>SAT</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/OET">
                                <div className="course-button">
                                    <p>OET</p>
                                    <div><ArrowForwardIcon/></div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/expert-english">
                                <div className="course-button expert-english-button">
                                    <p>Expert English</p>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/government-exams">
                                <div className="course-button expert-english-button">
                                    <p>Government Exams</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={'banner-second-section'}>
                    <div className={'banner-second-item'}>
                        <img alt="Top Course for ielts,gmat,gre,toefl,pte"
                             src={'https://ugc.futurelearn.com/uploads/images/a7/68/thumbnail_a7689d21-c026-4845-a5a8-8ab3e74f7dfe.jpg'}/>
                        <div className={'first-div'}>
                            <p>Top Courses</p>
                        </div>
                        <div className={'item-ban'}>
                            <p>- Web and mobile support</p>
                            <p>- Detailed and Result driven content</p>
                            <p>- 24/7 Email assistance</p>
                        </div>
                    </div>
                    <div className={'banner-second-item'}>
                        <img alt="Online test for ielts, gre,gmat,toefl,pte"
                             src={'https://ugc.futurelearn.com/uploads/images/1e/0a/thumbnail_1e0aa143-5700-4ea3-aefc-b0050e415c62.jpg'}/>
                        <div className={'first-div'}>
                            <p>Online Tests</p>
                        </div>
                        <div className={'item-ban'}>
                            <p>- Simulated test conditions.</p>
                            <p>- Assessment by certified tutors</p>
                            <p>- Artificial Intelligence (AI) Algorithms.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}