import React from 'react';
import {Link} from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

export const Footer = () => {
    const facebook = 'https://www.facebook.com/'
    const instagram = 'https://www.instagram.com/testacademiatestprep/'
    const telegram = 'https://t.me/'
    const whatsapp = 'https://wa.me/919591722199?text='
    const twitter = 'https://twitter.com/'
    const youtube = 'https://youtube.com/channel/UCctmOI08LWYvDKCFPQ3LXAw'

    return (
        <footer>
            <div className={'footer-content'}>
                <div className={'footer-about-company'}>
                    <p className={'footer-name'}>Testacademia</p>
                    <p className={'footer-text'}>Thousands of students from 150+ countries, each with their own unique
                        story, learn with Testacademia every single day. Testacademia offers a one stop solution for
                        preparatory needs.</p>
                    <a href="tel:9591722199" className={'footer-number'}>+91 9591722199</a>
                    <Link to={'/contact'} className={'footer-email'}>support@testacademia.com, testacademiaglobal@gmail.com</Link>
                </div>
                <div className={'footer-section'}>
                    <p className={'footer-section-title'}>Our Products</p>
                    <Link to={'/expert-english'}>
                        <ArrowRightIcon/>
                        <p>Expert English</p>
                    </Link>
                    <Link to={'/IELTS'}>
                        <ArrowRightIcon/>
                        <p>IELTS</p>
                    </Link>
                    <Link to={'/TOEFL'}>
                        <ArrowRightIcon/>
                        <p>TOEFL</p>
                    </Link>
                    <Link to={'/GMAT'}>
                        <ArrowRightIcon/>
                        <p>GMAT</p>
                    </Link>
                    <Link to={'/PTE'}>
                        <ArrowRightIcon/>
                        <p>PTE</p>
                    </Link>
                    <Link to={'/GRE'}>
                        <ArrowRightIcon/>
                        <p>GRE</p>
                    </Link>
                    <Link to={'/SAT'}>
                        <ArrowRightIcon/>
                        <p>SAT</p>
                    </Link>
                    <Link to={'/OET'}>
                        <ArrowRightIcon/>
                        <p>OET</p>
                    </Link>
                </div>
                <div className={'footer-section'}>
                    <p className={'footer-section-title'}>Company</p>
                    <Link to={'/admission-counsel'} className={'admission-link'}>
                        <ArrowRightIcon/>
                        <p>Admission Counseling</p>
                    </Link>
                    <Link to={youtube} target={'_blank'}>
                        <ArrowRightIcon/>
                        <p>Free Videos & YouTube</p>
                    </Link>
                    <div className={'free-apps'}>
                        <p>Free Apps</p>
                        <a target={'_blank'} href={'https://play.google.com/store/apps/details?id=com.testacademia.ielts'}>
                            <ArrowRightIcon/>
                            <p>Testacademia</p>
                        </a>
                        <a target={'_blank'} href={'https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app'}>
                            <ArrowRightIcon/>
                            <p>Crucibledesk</p>
                        </a>
                    </div>
                    <Link to={'/about-us'}>
                        <ArrowRightIcon/>
                        <p>About Us</p>
                    </Link>
                    <Link to={'/contact'}>
                        <ArrowRightIcon/>
                        <p>Contact</p>
                    </Link>
                    <div className={'links'}>
                        <a href={whatsapp} target={'_blank'}>
                            <div id={'whatsapp'}>
                                <WhatsAppIcon className={'link-icon'}/>
                            </div>
                            <p>Whatsapp</p>
                        </a>
                        <a href={twitter} target={'_blank'}>
                            <div id={'twitter'}>
                                <TwitterIcon className={'link-icon'}/>
                            </div>
                            <p>Twitter</p>
                        </a>
                        <a href={youtube} target={'_blank'}>
                            <div id={'youtube'}>
                                <YouTubeIcon className={'link-icon'}/>
                            </div>
                            <p>Youtube</p>
                        </a>
                        <a href={facebook} target={'_blank'}>
                            <div id={'facebook'}>
                                <FacebookIcon className={'link-icon'}/>
                            </div>
                            <p>Facebook</p>
                        </a>
                        <a href={instagram} target={'_blank'}>
                            <div id={'instagram'}>
                                <InstagramIcon className={'link-icon'}/>
                            </div>
                            <p>Instagram</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className={'sub-footer'}>
                <div className={'sub-footer-content'}>
                    <p><a href={'#'}>® Testacademia 2020, All Rights Reserved</a></p>
                    <p><Link to={'/privacy-policy'}>Privacy Policy</Link></p>
                    <p><Link to={'/terms-and-condition'}>Terms & condition</Link></p>
                </div>
            </div>
        </footer>
    )
}