import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

export const FourthBannerGovt = () => {
    const testacademiaPlayMarketLink = 'https://play.google.com/store/apps/details?id=com.testacademia.ielts'
    const testacademiaAppStoreLink = 'https://apps.apple.com/in/app/complete-ielts-8-band-prep/id1524214654'
    const greenAppPlayMarketLink = 'https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app'
    const greenAppAppStoreLink = 'https://play.google.com/store/apps/details?id=com.crucibledesk.pte.crucibledesk_app'

    return (
        <div className={'fourth-banner govt-fourth'}>
            <div className={'fourth-banner-item'} id={'first-banner-item'}>
                <div className={'fourth-banner-item-promo'} style={{backgroundColor: 'rgba(43, 43, 43, 0.8)'}}>
                    <p>ACE any government exam with us</p>
                </div>
            </div>
            <div className={'fourth-banner-item'} id={'second-banner-item'}>
                <div className={'fourth-banner-item-review'}>
                    <div className={'fourth-banner-item-review-content'} style={{paddingTop: "50px"}}>
                        <AccountCircleIcon  style={{fontSize:"5rem"}} />
                        <p className={'review-title'}>50+ Tutors <br></br>Onboard</p>
                    </div>
                </div>
            </div>
            <div className={'fourth-banner-item'} id={'third-banner-item'}>
                <div className={'fourth-banner-item-review'}>
                    <div className={'fourth-banner-item-review-content'} style={{paddingTop: "50px"}}>
                        <VideoLibraryIcon style={{fontSize:"5rem"}} />
                        <p className={'review-title'}>1000+ Videos<br></br>Created</p>
                    </div>
                </div>
            </div>

        </div>
    )
}