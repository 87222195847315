import React,{useEffect} from 'react';
import {Link,useLocation} from "react-router-dom";

export const ExpertEnglishNavigation = (props) => {
    const {name, link, registerLink} = props

    const location=useLocation()

    useEffect(() => {
        console.log("Histoy is",location.pathname)
    })

    return (
        <div className={'header'}>
            <header className="header-container">
                <Link to="/">
                    <div className="navbar-brand logo_h">
                        <img src={require("../../../img/logo.jpg")} alt="TestAcademia Logo"/>
                    </div>
                </Link>
                <div className={'secondary-nav'}>
                    <ul className="header-navigation">
                        <li className="nav-item">
                            {
                                location.pathname==="/government-exams"?(
                                    null
                                ):(
                                    <Link to={link}>
                                <div className="nav-link">Pricing</div>
                            </Link>
                                )
                            }
                        </li>
                        <li className="nav-item product-list">
                            <a to="#" id={'blink'}>
                                <div className="nav-link product-list-head">Other products</div>
                            </a>
                            <div className={'dropdown-nav'}>
                                <Link to={'/IELTS'}>IELTS</Link>
                                <Link to={'/GRE'}>GRE</Link>
                                <Link to={'/TOEFL'}>TOEFL</Link>
                                <Link to={'/GMAT'}>GMAT</Link>
                                <Link to={'/SAT'}>SAT</Link>
                                <Link to={'/OET'}>OET</Link>
                                <Link to={'/PTE'}>PTE</Link>
                                <Link to={'/expert-english'}>Expert English</Link>
                                <Link to={'/government-exams'}>Government Exams</Link>
                                <Link to={'/IELTS-tests'}>Ielts Mock Tests</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link to="/blog">
                                <div className="nav-link">Blog</div>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/contact">
                                <div className="nav-link">{name} Advice</div>
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/contact">
                                <div className="nav-link">Contact</div>
                            </Link>
                        </li>
                    </ul>
                    {
                            location.pathname==="/government-exams"?(
                                <div className={'header-register'}>
                        <div className={'sign-up-link'}>
                            <a href={registerLink} className={'sign-up-link'}>Create Free Account</a>
                        </div>
                    </div>
                            ):(
                                <div className={'header-register'}>
                        
                                <div className={'sign-in-link'}>
                                    <a href={'https://ielts.testacademia.com/auth/login'} className={'sign-in-link'}>Log In</a>
                                </div>
                                <div className={'sign-up-link'}>
                                    <a href={registerLink} className={'sign-up-link'}>Create Free Account</a>
                                </div>
                            </div>       
                            )
                        }
                </div>
            </header>
        </div>
    )
}