import React from 'react';
import {Link} from "react-router-dom";

export const ProductBanner = ({data, type, video}) => {
    let videolink=""
    if(type === 'gre'){
        videolink = 'https://www.youtube.com/embed/3IAGiq3t59M'
    }
    else{
        videolink = 'https://www.youtube.com/embed/IBNrwplgcMo'
    }

    const link =videolink
   // const link = 'https://www.youtube.com/embed/ppJAMWDDJCA'

    return (
        <div className={`product-banner ${type}`}>
            <section style={video ? {background: "#9cccfd"} : undefined}>
                <div>
                    <span>
                        <img alt="Ielts, Gmat, Gre, Toefl, pte Preparation" src={data.img}/>
                        <p className={'product-banner-title'}>Unparalleled learning at the touch of a button.</p>
                        <p className={'product-banner-text'}>{data.text}</p>
                            <Link to={data.link} className={'product-banner-nav-link'}>Explore our plans</Link>
                    </span>
                    {video ? <iframe title='video' className={'video-product'}
                                     style={video ? {marginLeft: "50px"} : undefined} width="100%" height="100%"
                                     src={link} frameBorder="0"
                                     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                     allowFullScreen/> : <p className={'product-title'}>{data.title}</p>}
                </div>
            </section>
        </div>
    )
}