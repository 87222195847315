import React from 'react';
import {FourthBanner} from "../../Home/Banners/FourthBanner";
import {ProductBanner} from "../../common/ProductBanner";
import {ReviewSlider} from "../../Home/ReviewSlider";
import {InfoBanner} from "../../common/InfoBanner";
import {WhyWeBanner} from "../../common/WhyWeBanner";
import {ExpertEnglishNavigation} from "../ExpertEnglish/ExpertEnglishNavigation";
import {Universities} from "../../common/Universities";
import {ThirdBanner} from "../../Home/Banners/ThirdBanner";
import {Migration} from "../../common/Migration";

export const OET = () => {
    const data = {
        text: 'Improve your bands and achieve your dream scores today!',
        img: 'https://ugc.futurelearn.com/uploads/images/f3/db/thumbnail_f3db4027-c8b3-4510-bf31-1af27563678a.jpg',
        link: '/OET-pricing',
        name: 'OET',
        registerLink: '/OET-register',
        title: 'Best world class OET prep! Fulfil your dream of being a part of esteemed medical fraternity!'
    }

    const text = [
        {
            title: "Structured curriculum",
            text: "In depth engaging video lessons by leading OET experts with simple instructions and strategies for a high score. Stay focused!",
        },
        {
            title: "Most Affordable",
            text: "You get the best product and service at an unbeatable price",
        },
        {
            title: "Online support",
            text: "Assistance on your queries and doubt sessions is available at all times at a click of a button",
        }
    ]

    return (
        <div>
            <ExpertEnglishNavigation name={data.name} registerLink={data.registerLink} link={data.link}/>
            <ProductBanner title={data.title} type={'oet'} data={data}/>
            <WhyWeBanner text={text} name={data.name}/>
            <FourthBanner/>     
            <Universities/>
            <Migration/>       
            <InfoBanner link={data.link} name={data.name}/>
            <ReviewSlider/>
        </div>
    )
}