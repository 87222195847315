import React from 'react';
import {Link} from 'react-router-dom';

export default class BlogRightAd extends React.Component{
    render(){
        return(
          <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget popular_post_widget">
                    <h3 className="widget_title">Popular Blogs</h3>
                    <div className="media post_item">
                      <img alt="IELTS Listening made easy." src={'https://hallmarkuniversity.edu/wp-content/uploads/2020/04/book-macbook-pro-working-studying-7354-scaled.jpg'} alt="post" />
                      <div className="media-body">
                        <a href="../blogview/1">
                          <h3>IELTS Listening made easy.</h3>
                        </a>
                        <p>24 May 2021</p>
                      </div>
                    </div>
                    <div className="media post_item">
                      <img alt="Facing the biggest challenge in writing & speaking" src={'https://preply.com/wp-content/uploads/2018/04/education-concept-student-studying-and-brainstorming-campus-concept-close-up-of-students-discussing-their-subject-on-books-or-textbooks-selective-focus_1418-627-2.jpg'}  alt="post" />
                      <div className="media-body">
                        <a href="../blogview/2">
                          <h3>Facing the biggest challenge in writing & speaking</h3>
                        </a>
                        <p>12 Apr 2021</p>
                      </div>
                    </div>
                    <div className="br" />
                  </aside>
                </div>
        );
    }
}