import React, { useLayoutEffect ,useEffect} from 'react';
import { Route, useLocation} from "react-router-dom";
import './App.scss'

// Courses pages
import {ExpertEnglish} from "./components/Products/ExpertEnglish/ExpertEnglish";
import {IELTS} from './components/Products/IELTS/IELTS';
import {TOEFL} from './components/Products/TOEFL/TOEFL';
import {GMAT} from './components/Products/GMAT/GMAT';
import {PTE} from './components/Products/PTE/PTE';
import {GRE} from './components/Products/GRE/GRE';
import {SAT} from './components/Products/SAT/SAT';
import {OET} from './components/Products/OET/OET';
import {Govt} from './components/Products/Govt/Govt';
import {Testimonial} from './components/Testimonial/testimonial';
import {BlogSite} from './components/Blog/blogsite'
import BlogView from './components/Blog/blogview'

// Pricing pages
import {IELTSPricing} from "./components/Products/IELTS/IELTSPricing";
import {TOEFLPricing} from './components/Products/TOEFL/TOEFLPricing';
import {GMATPricing} from './components/Products/GMAT/GMATPricing';
import {PTEPricing} from './components/Products/PTE/PTEPricing';
import {GREPricing} from './components/Products/GRE/GREPricing';
import {SATPricing} from './components/Products/SAT/SATPricing';
import {OETPricing} from './components/Products/OET/OETPricing';
import {GovtPricing} from './components/Products/Govt/GovtPricing'

// Register pages
import ExpertEnglishRegisterPage from './components/Products/ExpertEnglish/RegisterPage.jsx'
import IeltsRegisterPage from './components/Products/IELTS/RegisterPage.jsx'
import TOEFLRegisterPage from './components/Products/TOEFL/RegisterPage.jsx'
import GmatRegisterPage from './components/Products/GMAT/RegisterPage.jsx'
import PteRegisterPage from './components/Products/PTE/RegisterPage.jsx'
import GreRegisterPage from './components/Products/GRE/RegisterPage.jsx'
import SATRegisterPage from './components/Products/SAT/RegisterPage.jsx'
import OETRegisterPage from './components/Products/OET/RegisterPage.jsx'

// Other pages
import {ExpertEnglishPricing} from "./components/Products/ExpertEnglish/ExpertEnglishPricing";
import {UserInteractive} from "./components/common/UserInteractive";
import {PrivacyPolicy} from './components/common/PrivacyPolicy';
import {TermsAndCond} from './components/common/TermsAndCond';
import {IELTSTest} from "./components/common/IELTSTest";
import {Admission} from './components/common/Admission';
import {AboutUs} from './components/common/AboutUs';
import {Contact} from './components/common/Contact';
import {Footer} from "./components/common/Footer";
import {Home} from "./components/Home/Home"

export const App = () => {
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        
            <>
                <Route path="/" exact component={Home}/>
                <Route path="/IELTS-tests" component={IELTSTest}/>
                <Route path="/about-us" component={AboutUs}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/admission-counsel" component={Admission}/>
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/terms-and-condition" component={TermsAndCond}/>

                <Route path="/expert-english" component={ExpertEnglish}/>
                <Route path="/IELTS" component={IELTS}/>
                <Route path="/TOEFL" component={TOEFL}/>
                <Route path="/GMAT" component={GMAT}/>
                <Route path="/PTE" component={PTE}/>
                <Route path="/GRE" component={GRE}/>
                <Route path="/SAT" component={SAT}/>
                <Route path="/OET" component={OET}/>
                <Route path="/government-exams" component={Govt}/>
                <Route path="/testimonials" component={Testimonial}/>
                <Route path="/blog" component={BlogSite}/>
                <Route path="/blogview/:id" component={BlogView} />

                <Route path="/PTE-pricing" component={PTEPricing}/>
                <Route path="/GMAT-pricing" component={GMATPricing}/>
                <Route path="/GRE-pricing" component={GREPricing}/>
                <Route path="/TOEFL-pricing" component={TOEFLPricing}/>
                <Route path="/SAT-pricing" component={SATPricing}/>
                <Route path="/OET-pricing" component={OETPricing}/>
                <Route path="/IELTS-pricing" component={IELTSPricing}/>
                <Route path="/govt-pricing" component={GovtPricing}/>
                <Route path="/expert-english-pricing" component={ExpertEnglishPricing}/>

                <Route path="/expert-english-register" component={ExpertEnglishRegisterPage}/>
                <Route path="/IELTS-register" component={IeltsRegisterPage}/>
                <Route path="/TOEFL-register" component={TOEFLRegisterPage}/>
                <Route path="/GMAT-register" component={GmatRegisterPage}/>
                <Route path="/PTE-register" component={PteRegisterPage}/>
                <Route path="/GRE-register" component={GreRegisterPage}/>
                <Route path="/SAT-register" component={SATRegisterPage}/>
                <Route path="/OET-register" component={OETRegisterPage}/>

                <UserInteractive/>
                <Footer/>
            </>
       
    )
}