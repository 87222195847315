import React, {useEffect, useState} from "react";
import {PricingPage} from "../../common/PricingPage";
import {PricingQuestions} from "../../common/PricingQuestions";
import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
import {Preloader} from "../../common/Preloader";

export const IELTSPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/IELTS-register'
    const name = 'IELTS'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/package/bannerpackage')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>
    return (
        <div>
            <PricingPage name={name} registerLink={'https://ielts.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                   link={link}
                                                   key={item.price}/>)}
            </div>
            <PricingQuestions/>
        </div>
    )
}