import React from 'react'
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import SmartphoneOutlinedIcon from '@material-ui/icons/SmartphoneOutlined';

export const ThirdBanner = () => {

    return (
        <div className={'third-banner'}>
            <p className={'third-banner-title'}>Cross Platform Access</p>
            <div className={'third-banner-content'}>
                <div className={'third-banner-item'}>
                    <div className={'third-banner-icon'}>
                        <DesktopWindowsOutlinedIcon className={'third-banner-icon'}/>
                    </div>
                    <p className={'third-banner-item-title'}>Web support</p>
                    <p className={'third-banner-item-subtitle'}>Prep anytime from your desktop</p>
                    <div className={'third-banner-text'}>
                        <p>Exhaustive video lessons by experts</p>
                        <p>Extensive range of practice questions</p>
                        <p>Dedicated support team</p>
                        <p>Track your progress</p>
                    </div>
                </div>
                <div className={'third-banner-item'}>
                    <div className={'third-banner-icon'}>
                        <SmartphoneOutlinedIcon className={'third-banner-icon'}/>
                    </div>
                    <p className={'third-banner-item-title'}>Mobile support</p>
                    <p className={'third-banner-item-subtitle'}>Prep anytime from your mobile</p>
                    <div className={'third-banner-text'}>
                        <p>Study on-the-go with our free apps</p>
                        <p>Previous year Practice questions</p>
                        <p>Detailed grammar lessons</p>
                        <p>Vocabulary builder</p>
                    </div>
                </div>
            </div>
        </div>
    )
}