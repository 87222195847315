import React, {useEffect, useState} from "react";
// import {PricingPage} from "../../common/PricingPage";
// import {PricingQuestions} from "../../common/PricingQuestions";
import {PricingPageForSAT} from "../../common/PricingPages/PricingPageForSAT";
import {PricingQuestionsForSAT} from "../../common/PricingQuestionsPages/PricingQuestionsForSAT";
import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
import {Preloader} from "../../common/Preloader";

export const SATPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/SAT-register'
    const name = 'SAT'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/sat/package/bannerpackage')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>
    return (
        <div>
            <PricingPageForSAT name={name} registerLink={'https://sat.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                                  link={link}
                                                                  key={item.price}/>)}
            </div>
            <PricingQuestionsForSAT/>
        </div>
    )
}