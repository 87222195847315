import React from 'react'
import {Link} from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowRightAlt';

export const GovtBanner = () => {

    return (
        <div className={'govt-banner'}>

        <div className={'banner-area govt-area'}>
            <div className={'banner'}>
                <div className={'banner-govt-first-section'}>
                    <p>India's Largest online platform for job preparation</p>
                    <p className={'home-subtitle'} style={{marginBottom:"45px"}}>Competitive Exams ki taiyari karen apni bhasha mein</p>
                    <Link style={{background:"#ff4a6e",color:"#fff",fontSize:"17px",fontWeight:"500",textDecoration:"none",padding:"10px 15px",borderRadius:"4px"}}>Coming Soon</Link>
                </div>
            </div>
        </div>

        <div className={'govt-pic'}>
            <img src="https://images.shiksha.ws/mediadata/images/articles/exams2.jpg" />
        </div>
        </div>
    )
}