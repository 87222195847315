import React from "react";
import {Navigation} from "./Navigation";

export const PrivacyPolicy = () => {

    return (
        <div>
            <Navigation/>
            <div className={'privacy-policy'}>
                <p className={'page-title'}>Privacy Policy</p>

                <h2>What is it?</h2>

                This Privacy Notice explains how information is collected, used, stored, and disclosed by Testacademia.
                It
                applies to information collected when you use or access our public websites, mobile apps and software
                product , or otherwise interact with us.

                <h2>Ammendments</h2>

                We may change this Privacy Notice from time to time. If we make any changes, we will notify you by
                revising
                the "Last Updated" date at the top of this Privacy Notice and, in some cases, we may provide you with
                additional notice (such as adding a statement to our homepage or sending you an email notification).

                If there are material changes to this Privacy Notice, we will notify you directly by email or by means
                of a
                notice on the homepage prior to the change becoming effective.

                If our information practices change at some time in the future we will post the policy changes to our
                public
                websites to notify you of these changes and provide you with the ability to opt out of these new uses.

                <h2>What Information We Collect</h2>

                We may collect certain user information, including personal data.

                Information You Provide To Us. We collect information you provide directly to us including when you use
                the
                Testacademia Service. This information includes personal data. This personal data will be processed for
                the
                purposes described in this Privacy Notice, as it may be amended from time to time. For example, your
                personal data will be used to create or modify your profile and account, access and use the Testacademia
                Service (including but not limited to when you watch lessons, answer practice questions, or use a mobile
                app), participate in any interactive features of the Service, submit a contact or consent form,
                participate
                in a survey, or request customer support.

                The personal data that we may collect directly from you includes your name, username, email address,
                phone
                number, information about your Product account preferences, transactional information (including
                services
                purchased or subscribed to and amount paid), as well as any contact or other information you choose to
                provide.

                If you are a member of a social media network, like Facebook, you may choose to use your social
                credentials
                to create and log into your Testacademia account.

                Testacademia does not save any personal financial information, including credit card numbers, expiration
                dates, security codes, or postal codes.


                Information We Collect Automatically When You Use our Service.

                Usage, Log and Device Information: We collect information from your use of the Testacademia Service,
                such as
                number of visits, length of time spent using or accessing our Products, IP address (which may allow us
                to
                collect general location data), pages clicked on, system activity, hardware settings, device type,
                operating
                system, browser type, browser language, the date and time of your visit, and the URL you were on before
                you
                came to the our website.

                Information Collected by Cookies and Other Tracking Technologies: A cookie is a small file containing a
                string of characters that is sent to your computer when you visit a website. When you visit the website
                again, the cookie allows that site to recognise your browser. Cookies may store user preferences and
                other
                information. You can set your browser to refuse all cookies or to indicate when a cookie is being sent.
                However, some website features or services may not function properly without cookies.

                <h2>Information Collected ?</h2>

                We use the information collected:

                To provide, operate, maintain, and improve the Testacademia Service;

                To enable you to access and use our Service that you request, including sending you technical notices,
                updates, alerts, and support and administrative messages;

                To provide and deliver the services and features you request, process and complete transactions, and
                send
                you related information, including purchase confirmations and receipts;

                To respond to your comments, questions, and requests, and provide customer service and support;

                To process and deliver survey entries and rewards;

                To investigate and prevent fraudulent transactions, unauthorised access to our Service, and other
                illegal
                activities

                Social media network credentials enable us to link your activities with your personal registration
                information at their site, including name, email address, and profile image. The social network may
                (with
                your permission) notify your friends or connections about your activities in our Services and use
                tracking
                technologies to monitor your online activities to serve targeted ads.

                <h2>Cookies:</h2>

                We use cookies to understand and save your preferences for future visits and compile aggregate data
                about
                Product traffic and Product interaction so that we can offer better Product experiences and tools in the
                future.

                We may contract with third-party service providers to assist us to serve ads based on past visits to our
                Products, report on the performance of our ads, conduct analytics on the use of our Products, fulfill
                orders
                and other user requests, fraud prevention, improve our Products and conduct surveys relating to our
                users’
                experiences with our Products, and communicate with our users. For instance, we use Google Analytics
                Demographics and Interest Reporting, which includes data from interest-based advertising or third-party
                audience data (such as age, gender, and interests), for all of the purposes listed above.

                <h2>Retention of Information

                    Testacademia will keep personal information of its users for as long as they are registered
                    subscribers
                    or users of our products and services, and for a reasonable time thereafter
                </h2>

                <h2>What Information We Share or Disclose to Others</h2>

                We will not share personal data about you with any third parties except as described in this Privacy
                Notice,
                or as defined in an agreement with us in connection with the Testacademia Service. Information that may
                be
                shared, pursuant to any applicable agreements, includes:

                Third-Party Service Providers. We may share your information with third-party service providers who are
                working on our behalf and require access to your information to carry out that work, such as to process
                payment or as it relates to a Product feature. These service providers are authorised to use your
                personal
                data only as necessary to provide services to Testacademia.

                Compliance with Laws. We may disclose your information to a third party: (a) if we believe that
                disclosure
                is reasonably necessary to comply with any applicable law, regulation, legal process, or government
                request;
                (b) to enforce our agreements and policies; (c) to protect the security or integrity of the Testacademia
                Service; (d) to protect our customers, or the public from harm or illegal activities or medical
                contingencies or emergencies.

                .

                <h2>Third-Party Websites</h2>

                We may place links on the Testacademia Service, including the Blog. When you click on a link from our
                Product to a third-party website, your activity and use is governed by that website’s policies; not by
                those
                of Testacademia. We encourage you to review their privacy and user policies.

                <h2>Arbitration

                    Any dispute or claims arising between the parties hereto shall be referred to arbitration by an
                    arbitrator to be appointed by the Testacademia. The arbitration shall be held at Karnataka , India
                    and
                    the same shall be governed by the provisions of the Arbitration and Conciliation act, 1996 and
                    amendments thereto.
                    Jurisdiction

                    Any dispute shall be subject to the exclusive jurisdiction of the courts in Karnataka, India only.
                </h2>
            </div>
        </div>
    )
}