import React, {useEffect, useState} from "react";
import {PricingPageForPTE} from "../../common/PricingPages/PricingPageForPTE";
import {PricingQuestionsForPTE} from "../../common/PricingQuestionsPages/PricingQuestionsForPTE";
import axios from "axios";
import {PricingCard} from "../../common/PricingCard";
import {Preloader} from "../../common/Preloader";

export const PTEPricing = () => {
    const [products, setProducts] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const link = '/PTE-register'
    const name = 'PTE'

    useEffect(() => {
        axios.post('https://api.testacademia.com/admin/pte/package/bannerpackage')
            .then(res => setProducts([...res.data]))
        setIsLoaded(true)
    }, [])

    if (!isLoaded) return <Preloader/>
    return (
        <div>
            <PricingPageForPTE name={name} registerLink={'https://pte.testacademia.com/'}/>
            <div className={'pricing-cards'}>
                {products[0] && products.map(item => <PricingCard item={item}
                                                                  link={link}
                                                                  key={item.price}/>)}
            </div>
            <PricingQuestionsForPTE/>
        </div>
    )
}